const env = process.env.REACT_APP_NODE_ENV;

const url = {
  development: {
    BASE_URL_BE: "http://localhost:8000",
    BASE_URL_FE: "http://localhost:3000",
  },
  trial: {
    BASE_URL_BE: "https://be.upahku.com",
    BASE_URL_FE: "https://upahku.com",
  },
  production: {
    BASE_URL_BE: "https://be.upahku.com",
    BASE_URL_FE: "https://upahku.com",
  },
};

export const BASE_URL_BE = url[env].BASE_URL_BE;
export const BASE_URL_FE = url[env].BASE_URL_FE;
