import { getMethodToken } from "../../utils/httpMethod/getMethodToken";

export const getWageStructureYearsOfServiceTemplateFilename = async (
  config
) => {
  const res = await getMethodToken(
    "admin/wage_structure_years_of_service/get_template_filename",
    config
  );
  return res;
};
