export const attendanceSummaryColumn = [
  { field: "attendance_summary_id", headerName: "ID", minWidth: 70 },
  {
    field: "nip",
    headerName: "NIP",
    minWidth: 250,
  },
  {
    field: "nama",
    headerName: "Nama",
    minWidth: 250,
  },
  {
    field: "departemen",
    headerName: "Departemen",
    minWidth: 250,
  },
  {
    field: "grade",
    headerName: "Grade",
    minWidth: 250,
  },
  {
    field: "jabatan",
    headerName: "Jabatan",
    minWidth: 250,
  },
  {
    field: "harikerja",
    headerName: "Hari Kerja",
    minWidth: 250,
  },
  {
    field: "statushk",
    headerName: "Status HK",
    minWidth: 250,
  },
  {
    field: "terlambat",
    headerName: "Terlambat (Jam)",
    minWidth: 250,
  },
  {
    field: "pulangcepat",
    headerName: "Pulang Cepat (Jam)",
    minWidth: 250,
  },
  {
    field: "alpha",
    headerName: "Alpha (Hari)",
    minWidth: 250,
  },
  {
    field: "ijindipotong",
    headerName: "Ijin Dipotong (Hari)",
    minWidth: 250,
  },
  {
    field: "off",
    headerName: "OFF (Hari)",
    minWidth: 250,
  },
  {
    field: "ijinperintah",
    headerName: "Ijin Perintah (Hari)",
    minWidth: 250,
  },
  {
    field: "sakit",
    headerName: "Sakit (Hari)",
    minWidth: 250,
  },
  {
    field: "cuti",
    headerName: "Cuti (Hari)",
    minWidth: 250,
  },
  {
    field: "cutibersama",
    headerName: "Cuti Bersama (Hari)",
    minWidth: 250,
  },
  {
    field: "totaltidakhadir",
    headerName: "Total Tidak Hadir (Hari)",
    minWidth: 250,
  },
  {
    field: "totalabsen",
    headerName: "Total Absen (Hari)",
    minWidth: 250,
  },
  {
    field: "totalhadir",
    headerName: "Total Hadir (Hari)",
    minWidth: 250,
  },
  {
    field: "totaljamlembur1",
    headerName: "Total Jam Lembur 1",
    minWidth: 250,
  },
  {
    field: "totaljamlembur2",
    headerName: "Total Jam Lembur 2",
    minWidth: 250,
  },
  {
    field: "totaljamlembur3",
    headerName: "Total Jam Lembur 3",
    minWidth: 250,
  },
  {
    field: "totaljamlembur4",
    headerName: "Total Jam Lembur 4",
    minWidth: 250,
  },
];
