import MuiPagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  gridPageCountSelector,
  GridPagination,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import React from "react";
import Empty from "../../Empty/Empty";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .no-rows-primary": {
    fill: theme.palette.mode === "light" ? "#AEB8C2" : "#3D4751",
  },
  "& .no-rows-secondary": {
    fill: theme.palette.mode === "light" ? "#E8EAED" : "#1D2126",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Empty />
    </StyledGridOverlay>
  );
}

function Pagination({ className, onPageChange, page }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      className={className}
      color="primary"
      count={pageCount}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      page={page + 1}
      size="small"
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const AdminDataGrid = ({
  columns,
  dataGridState,
  processRowUpdate,
  setDataGridState,
}) => {
  return (
    <DataGrid
      checkboxSelection
      columns={columns}
      disableDensitySelector
      disableRowSelectionOnClick
      editMode="row"
      getRowId={(row) => row[dataGridState.rowId]}
      initialState={{
        columns: {
          columnVisibilityModel: dataGridState.columnVisibilityModel,
        },
        pagination: { paginationModel: { pageSize: 50 } },
      }}
      loading={dataGridState.isLoading}
      onColumnVisibilityModelChange={(newModel) => {
        setDataGridState((prevState) => ({
          ...prevState,
          columnVisibilityModel: newModel,
        }));
      }}
      onFilterModelChange={(filterModel) => {
        setDataGridState((prevState) => ({
          ...prevState,
          filterModel: filterModel,
        }));
      }}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setDataGridState((prevState) => ({
          ...prevState,
          rowSelectionModel: newRowSelectionModel,
        }));
      }}
      onSortModelChange={(sortModel) => {
        setDataGridState((prevState) => ({
          ...prevState,
          sortModel: sortModel,
        }));
      }}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      processRowUpdate={(newRow) => {
        processRowUpdate(newRow);
        return newRow;
      }}
      rows={dataGridState.rows}
      slotProps={{
        toolbar: {
          printOptions: { disableToolbarButton: true },
          showQuickFilter: true,
        },
      }}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
        pagination: CustomPagination,
        toolbar: GridToolbar,
      }}
      sx={{ height: "65vh", minHeight: 500, width: "100%" }}
    />
  );
};

export default AdminDataGrid;
