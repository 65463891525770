import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import AuthElement from "../../../../components/AuthElement/AuthElement";
import { features } from "../../../../constants/features";
import { modules } from "../../../../constants/modules";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { manageCompanySchema } from "../../../../schemas/manageCompanySchema";
import { createCompany } from "../../../../services/manageCompany/createCompany";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const ManageCompanyCreate = () => {
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(manageCompanySchema),
  });
  const snackbarContext = useContext(SnackbarContext);

  const onSubmitCreateCompany = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      const tempCompany = {
        companyEmail: data.companyEmail,
        companyName: data.companyName,
        password: data.password,
        username: data.username,
      };

      const res = await createCompany(tempCompany);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitCreateCompany)}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <TextField
            {...register("companyEmail")}
            error={!!errors.companyEmail}
            fullWidth
            helperText={errors.companyEmail?.message}
            label="Email Perusahaan"
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <TextField
            {...register("companyName")}
            error={!!errors.companyName}
            fullWidth
            helperText={errors.companyName?.message}
            label="Nama Perusahaan"
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <TextField
            {...register("username")}
            error={!!errors.username}
            fullWidth
            helperText={errors.username?.message}
            label="Username"
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel error={!!errors.password}>Password</InputLabel>
            <OutlinedInput
              {...register("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setIsShowPassword(!isShowPassword)}
                    edge="end"
                  >
                    {isShowPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              error={!!errors.password}
              label="Password"
              type={isShowPassword ? "text" : "password"}
            />
            <FormHelperText error>{errors.password?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel error={!!errors.confirmPassword}>
              Confirm Password
            </InputLabel>
            <OutlinedInput
              {...register("confirmPassword")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setIsShowConfirmPassword(!isShowConfirmPassword)
                    }
                    edge="end"
                  >
                    {isShowConfirmPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              error={!!errors.confirmPassword}
              label="Confirm Password"
              type={isShowConfirmPassword ? "text" : "password"}
            />
            <FormHelperText error>
              {errors.confirmPassword?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <AuthElement
          moduleFeatures={{
            [modules.MANAGE_COMPANY.key]: {
              module: modules.MANAGE_COMPANY.key,
              features: [features.CREATE_DATA],
            },
          }}
        >
          <Grid item xs={12}>
            <Stack
              direction={{ sm: "row", xs: "column" }}
              justifyContent="flex-end"
              spacing={2}
            >
              <LoadingButton
                endIcon={<AddIcon />}
                loading={isLoadingButtonState.buttonCreate}
                loadingPosition="end"
                sx={{ mt: 2 }}
                type="submit"
              >
                Create
              </LoadingButton>
            </Stack>
          </Grid>
        </AuthElement>
      </Grid>
    </form>
  );
};

export default ManageCompanyCreate;
