import * as yup from "yup";

export const manageUserSchema = yup.object().shape({
  confirm_password: yup
    .string()
    .max(
      12,
      "The length of your Confirm Password must be 12 characters or fewer."
    )
    .min(
      3,
      "The length of your Confirm Password must be at least 3 characters."
    )
    .oneOf(
      [yup.ref("password")],
      "Password and Confirm Password does not match."
    )
    .required("Please enter your Confirm Password."),
  password: yup
    .string()
    .max(12, "The length of your Password must be 12 characters or fewer.")
    .min(3, "The length of your Password must be at least 3 characters.")
    .required("Please enter your Password."),
  username: yup
    .string()
    .max(32, "The length of your Username must be 32 characters or fewer.")
    .min(2, "The length of your Username must be at least 2 characters.")
    .required("Please enter your Username."),
  user_role: yup.object().required("Please enter your Role."),
});
