import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getManageUserColumns = (
  dialogContext,
  handleDeleteManageUser,
  handleUpdateManageUser
) => [
  { field: "id", headerName: "ID", minWidth: 70 },
  {
    field: "username",
    flex: 3,
    headerName: "Username",
    minWidth: 150,
  },
  {
    field: "role_name",
    flex: 3,
    headerName: "Nama Peran",
    minWidth: 150,
  },
  {
    field: "actions",
    headerName: "Aksi",
    minWidth: 150,
    type: "actions",
    getActions: (params) => [
      <Tooltip placement="left" title="Edit">
        <GridActionsCellItem
          icon={<EditIcon color="info" />}
          label="Edit"
          onClick={() => {
            handleUpdateManageUser(params.id);
          }}
        />
      </Tooltip>,
      <Tooltip placement="right" title="Delete">
        <GridActionsCellItem
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => {
            dialogContext.handleOpenDialog(
              () => handleDeleteManageUser(params.id),
              "Are you sure want to delete?",
              "Delete Confirmation"
            );
          }}
        />
      </Tooltip>,
    ],
  },
];
