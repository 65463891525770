import * as yup from "yup";

export const loginSchema = yup.object().shape({
  company_code: yup
    .string()
    .min(4, "The length of your Company Code must be at least 4 characters."),
  username: yup
    .string()
    .max(32, "The length of your Username must be 32 characters or fewer.")
    .required("Please enter your Username."),
  password: yup
    .string()
    .max(12, "The length of your Password must be 12 characters or fewer.")
    .min(6, "The length of your Password must be at least 6 characters."),
});
