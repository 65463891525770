import { postMethodToken } from "../../utils/httpMethod/postMethodToken";

export const importPph21TerTemplate = async (data) => {
  const res = await postMethodToken("admin/pph21_ter/import_template", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
