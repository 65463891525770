import { postMethodToken } from "../../utils/httpMethod/postMethodToken";

export const exportPph21CalculationTerData = async (data, config) => {
  const res = await postMethodToken(
    "admin/pph21_calculation_ter/export_data",
    data,
    config
  );
  return res;
};
