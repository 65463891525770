import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Grid, Paper, Stack, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { getPeriod } from "../../../services/managePeriod/getPeriod";
import { updatePeriod } from "../../../services/managePeriod/updatePeriod";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";

const ManagePeriod = () => {
  const autocompleteManagePeriod = [
    { label: "Januari", value: 1 },
    { label: "Februari", value: 2 },
    { label: "Maret", value: 3 },
    { label: "April", value: 4 },
    { label: "Mei", value: 5 },
    { label: "Juni", value: 6 },
    { label: "Juli", value: 7 },
    { label: "Agustus", value: 8 },
    { label: "September", value: 9 },
    { label: "Oktober", value: 10 },
    { label: "November", value: 11 },
    { label: "Desember", value: 12 },
  ];

  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [selectedManagePeriod, setSelectedManagePeriod] = useState(null);
  const snackbarContext = useContext(SnackbarContext);

  const fetchPeriod = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getPeriod();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        const tempSelectedManagePeriod =
          autocompleteManagePeriod[res.payload.manage_period_month - 1];

        setSelectedManagePeriod(tempSelectedManagePeriod);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdatePeriod = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await updatePeriod({
        manage_period_month: selectedManagePeriod.value,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    fetchPeriod();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            disableClearable
            disablePortal
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(_, newValue) => {
              setSelectedManagePeriod(newValue);
            }}
            options={autocompleteManagePeriod}
            renderInput={(params) => (
              <TextField {...params} label="Pilih Periode" />
            )}
            value={selectedManagePeriod}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<SaveIcon />}
              loading={isLoadingButtonState.buttonUpdate}
              loadingPosition="end"
              onClick={handleUpdatePeriod}
              sx={{ mt: 2 }}
            >
              Save
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManagePeriod;
