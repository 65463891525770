import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useOutletContext } from "react-router-dom";
import { labels } from "../../../constants/labels";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { getMinimumWage } from "../../../services/manageMinimumWage/getMinimumWage";
import { getMinimumWageAutocomplete } from "../../../services/manageMinimumWage/getMinimumWageAutocomplete";
import { updateMinimumWage } from "../../../services/manageMinimumWage/updateMinimumWage";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";

const ManageMinimumWage = () => {
  const [autocompleteManageMinimumWage, setAutocompleteManageMinimumWage] =
    useState([]);
  const [
    isLoadingAutocompleteManageMinimumWage,
    setIsLoadingAutocompleteManageMinimumWage,
  ] = useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [
    isOpenAutocompleteManageMinimumWage,
    setIsOpenAutocompleteManageMinimumWage,
  ] = useState(false);

  const [selectedManageMinimumWage, setSelectedManageMinimumWage] =
    useState(null);
  const {
    control,
    formState: { errors },
  } = useForm();
  const snackbarContext = useContext(SnackbarContext);

  const fetchMinimumWage = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getMinimumWage();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setSelectedManageMinimumWage(res.payload);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateMinimumWage = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await updateMinimumWage({
        manage_minimum_wage_country_minimum_wage_id:
          selectedManageMinimumWage?.country_minimum_wage_id ?? 0,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // fetch minimum wage autocomplete
  useEffect(() => {
    setAutocompleteManageMinimumWage([]);

    if (!isOpenAutocompleteManageMinimumWage) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteManageMinimumWage(true);

        const res = await getMinimumWageAutocomplete();

        if (res.status === 200) {
          setAutocompleteManageMinimumWage(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
        throw error;
      } finally {
        setIsLoadingAutocompleteManageMinimumWage(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteManageMinimumWage]);

  useEffect(() => {
    fetchMinimumWage();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Autocomplete
            disabled={isLoadingLinearProgress}
            getOptionLabel={(option) =>
              option[labels.COUNTRY_MINIMUM_WAGE.KABUPATEN_KOTA]
            }
            isOptionEqualToValue={(option, value) =>
              option.country_minimum_wage_id === value.country_minimum_wage_id
            }
            loading={isLoadingAutocompleteManageMinimumWage}
            onChange={(_, value) => {
              setSelectedManageMinimumWage(value);
            }}
            open={isOpenAutocompleteManageMinimumWage}
            onOpen={() => {
              setIsOpenAutocompleteManageMinimumWage(true);
            }}
            onClose={() => {
              setIsOpenAutocompleteManageMinimumWage(false);
            }}
            options={autocompleteManageMinimumWage}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Kabupaten Kota"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoadingAutocompleteManageMinimumWage ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                placeholder="Pilih Kabupaten Kota"
              />
            )}
            value={selectedManageMinimumWage}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="umk"
            render={({ field: { name, onChange, ref, value } }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                disabled
                error={!!errors.umk}
                fullWidth
                helperText={errors.umk?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue > 0;
                }}
                label="UMK"
                name={name}
                onChange={onChange}
                thousandSeparator=","
                value={
                  selectedManageMinimumWage?.[
                    labels.COUNTRY_MINIMUM_WAGE.UMK
                  ] ?? 0
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<SaveIcon />}
              loading={isLoadingButtonState.buttonUpdate}
              loadingPosition="end"
              onClick={handleUpdateMinimumWage}
              sx={{ mt: 2 }}
            >
              Save
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManageMinimumWage;
