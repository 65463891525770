import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";
import { globalTheme } from "./globalTheme";

export const darkTheme = createTheme({
  ...globalTheme,
  palette: {
    action: {
      focus: "rgba(255, 255, 255, 0.15)",
      hover: "rgba(255, 255, 255, 0.1)",
      selected: "rgba(255, 255, 255, 0.2)",
    },
    mode: "dark",
    primary: {
      dark: orange[600],
      light: orange[300],
      main: orange[400],
    },
  },
});
