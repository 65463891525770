import {
  faFileArrowDown,
  faFileArrowUp,
  faFileExport,
  faFileImport,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { parseISO } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AuthElement from "../../../../components/AuthElement/AuthElement";
import AdminDataGridPro from "../../../../components/admin/AdminDataGridPro";
import { features } from "../../../../constants/features";
import { modules } from "../../../../constants/modules";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { createBpjsKetenagakerjaan } from "../../../../services/bpjsKetenagakerjaan/createBpjsKetenagakerjaan";
import { deleteBpjsKetenagakerjaan } from "../../../../services/bpjsKetenagakerjaan/deleteBpjsKetenagakerjaan";
import { exportBpjsKetenagakerjaanData } from "../../../../services/bpjsKetenagakerjaan/exportBpjsKetenagakerjaanData";
import { exportBpjsKetenagakerjaanTemplate } from "../../../../services/bpjsKetenagakerjaan/exportBpjsKetenagakerjaanTemplate";
import { getBpjsKetenagakerjaan } from "../../../../services/bpjsKetenagakerjaan/getBpjsKetenagakerjaan";
import { getBpjsKetenagakerjaanFilename } from "../../../../services/bpjsKetenagakerjaan/getBpjsKetenagakerjaanFilename";
import { getBpjsKetenagakerjaanTemplateFilename } from "../../../../services/bpjsKetenagakerjaan/getBpjsKetenagakerjaanTemplateFilename";
import { importBpjsKetenagakerjaanData } from "../../../../services/bpjsKetenagakerjaan/importBpjsKetenagakerjaanData";
import { importBpjsKetenagakerjaanTemplate } from "../../../../services/bpjsKetenagakerjaan/importBpjsKetenagakerjaanTemplate";
import { replaceBpjsKetenagakerjaanData } from "../../../../services/bpjsKetenagakerjaan/replaceBpjsKetenagakerjaanData";
import { updateBpjsKetenagakerjaan } from "../../../../services/bpjsKetenagakerjaan/updateBpjsKetenagakerjaan";
import { getSingleSelectData } from "../../../../services/singleSelect/getSingleSelectData";
import convertRulesToMUIDataType from "../../../../utils/convertRulesToMUIDataType";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";
import getDataGridColumnWidth from "../../../../utils/getDataGridColumnWidth";
import getSingleSelectFromRules from "../../../../utils/getSingleSelectFromRules";
import isDataGridCellEditable from "../../../../utils/isDataGridCellEditable";
import isValidDate from "../../../../utils/isValidDate";

const BpjsKetenagakerjaan = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataGridState, setDataGridState] = useState({
    columns: [],
    columnVisibilityModel: {},
    filterModel: {},
    isLoading: false,
    pinnedRows: { top: [], bottom: [] },
    rowId: "bpjs_ketenagakerjaan_id",
    rowSelectionModel: [],
    rows: [],
    sortModel: [],
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
    buttonDelete: false,
    buttonExportData: false,
    buttonExportTemplate: false,
    buttonImportData: false,
    buttonImportTemplate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const importBpjsKetenagakerjaanDataRef = useRef();
  const importBpjsKetenagakerjaanTemplateRef = useRef();
  const open = Boolean(anchorEl);
  const replaceBpjsKetenagakerjaanDataRef = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const fetchBpjsKetenagakerjaanData = async () => {
    try {
      // get drop down value
      let singleSelectData = await fetchSingleSelectData();

      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getBpjsKetenagakerjaan();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        initBpjsKetenagakerjaanDataGrid(
          res.payload.query,
          singleSelectData,
          res.templateData
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchSingleSelectData = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getSingleSelectData(
        modules.BPJS_KETENAGAKERJAAN.key.toLowerCase()
      );

      if (res.status === 200) {
        return res.payload;
      } else {
        return [];
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCreateBpjsKetenagakerjaan = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      let res = await getBpjsKetenagakerjaanTemplateFilename();

      if (res.status !== 200) {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await createBpjsKetenagakerjaan({
        newBpjsKetenagakerjaanData: dataGridState.rows[0],
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      // if success then fetch
      if (res.status === 200) {
        fetchBpjsKetenagakerjaanData();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleDeleteBpjsKetenagakerjaan = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonDelete: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await deleteBpjsKetenagakerjaan({
        selectedBpjsKetenagakerjaanID: dataGridState.rowSelectionModel,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      // if success then fetch
      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rowSelectionModel: [],
        }));

        fetchBpjsKetenagakerjaanData();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonDelete: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportBpjsKetenagakerjaanData = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let res = await getBpjsKetenagakerjaanTemplateFilename();

      if (res.status !== 200) {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      let filename = null;

      res = await getBpjsKetenagakerjaanFilename();

      if (res.status === 200) {
        filename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportBpjsKetenagakerjaanData(
        {
          columnVisibilityModel: dataGridState.columnVisibilityModel,
          filterModel: dataGridState.filterModel,
          sortModel: dataGridState.sortModel,
        },
        { responseType: "arraybuffer" }
      );

      const link = document.createElement("a");
      const tempFilename = filename;
      link.setAttribute("download", tempFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Data Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportBpjsKetenagakerjaanTemplate = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportTemplate: true,
      }));
      setIsLoadingLinearProgress(true);

      let templateFilename = null;

      let res = await getBpjsKetenagakerjaanTemplateFilename();

      if (res.status === 200) {
        templateFilename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportBpjsKetenagakerjaanTemplate({
        responseType: "arraybuffer",
      });

      const link = document.createElement("a");
      const tempTemplateFilename = templateFilename;
      link.setAttribute("download", tempTemplateFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Template Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportTemplate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleImportBpjsKetenagakerjaanData = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "bpjsKetenagakerjaanData",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await importBpjsKetenagakerjaanData(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchBpjsKetenagakerjaanData();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("bpjs-ketenagakerjaan-data")) {
        document.getElementById("bpjs-ketenagakerjaan-data").value = "";
      }
    }
  };

  const handleImportBpjsKetenagakerjaanTemplate = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportTemplate: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "bpjsKetenagakerjaanTemplate",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await importBpjsKetenagakerjaanTemplate(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchBpjsKetenagakerjaanData();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportTemplate: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("bpjs-ketenagakerjaan-template")) {
        document.getElementById("bpjs-ketenagakerjaan-template").value = "";
      }
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReplaceBpjsKetenagakerjaanData = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "bpjsKetenagakerjaanData",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await replaceBpjsKetenagakerjaanData(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchBpjsKetenagakerjaanData();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("bpjs-ketenagakerjaan-data")) {
        document.getElementById("bpjs-ketenagakerjaan-data").value = "";
      }
    }
  };

  const handleUpdateBpjsKetenagakerjaan = async (newRow) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      // if update insert row
      if (newRow.bpjs_ketenagakerjaan_id === 0) {
        const tempNewBpjsKetenagakerjaanData = dataGridState.rows.map(
          (currentState) => {
            return currentState.bpjs_ketenagakerjaan_id ===
              newRow.bpjs_ketenagakerjaan_id
              ? newRow
              : currentState;
          }
        );

        setDataGridState((prevState) => ({
          ...prevState,
          rows: tempNewBpjsKetenagakerjaanData,
        }));
      } else {
        // update data at DB
        try {
          setDataGridState((prevState) => ({
            ...prevState,
            isLoading: true,
          }));
          setIsLoadingLinearProgress(true);

          const res = await updateBpjsKetenagakerjaan({
            newBpjsKetenagakerjaanData: newRow,
          });

          snackbarContext.handleOpenSnackbar(res.message, res.status);

          fetchBpjsKetenagakerjaanData();
        } catch (error) {
          snackbarContext.handleOpenSnackbar(
            generateSnackbarErrorMessage(error),
            "error"
          );
          throw error;
        } finally {
          setDataGridState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          setIsLoadingLinearProgress(false);
        }
      }
    } catch (error) {
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const initBpjsKetenagakerjaanDataGrid = (
    data,
    singleSelectData,
    templateData
  ) => {
    const gridColumns = [];
    const gridRows = [];
    const insertRow = {};

    // init column & insert row
    for (let i = 0; i < templateData.columns.length; i++) {
      let temp = {
        description:
          convertRulesToMUIDataType(templateData.rules[i]) === "date"
            ? "mm/dd/yyyy"
            : null,
        // if not id or formula then can edit
        editable: isDataGridCellEditable(templateData.rules[i]),
        field: templateData.fields[i],
        headerName: templateData.columns[i],
        minWidth: getDataGridColumnWidth(templateData.rules[i]),
        type: convertRulesToMUIDataType(templateData.rules[i]),
      };

      if (convertRulesToMUIDataType(templateData.rules[i]) === "singleSelect") {
        temp.valueOptions =
          singleSelectData[getSingleSelectFromRules(templateData.rules[i])];
      }

      gridColumns.push(temp);

      if (templateData.fields[i] === "bpjs_ketenagakerjaan_id") {
        insertRow[templateData.fields[i]] = 0;
      } else if (
        // avoid error (mui single select value cannot null)
        convertRulesToMUIDataType(templateData.rules[i]) === "singleSelect"
      ) {
        insertRow[templateData.fields[i]] = "";
      } else {
        insertRow[templateData.fields[i]] = null;
      }
    }

    gridRows.push(insertRow);

    // init row
    for (let i = 0; i < data.length; i++) {
      const keys = Object.keys(data[i]);

      for (let j = 0; j < keys.length; j++) {
        if (convertRulesToMUIDataType(templateData.rules[j]) === "date") {
          data[i][keys[j]] = parseISO(data[i][keys[j]]);

          if (!isValidDate(data[i][keys[j]])) {
            data[i][keys[j]] = "";
          }
        }
      }

      gridRows.push(data[i]);
    }

    setDataGridState((prevState) => ({
      ...prevState,
      columns: gridColumns,
      rows: gridRows,
    }));
  };

  useEffect(() => {
    fetchBpjsKetenagakerjaanData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={"flex-end"}
        spacing={2}
        sx={{ mb: 2 }}
      >
        <AuthElement
          moduleFeatures={{
            [modules.BPJS_KETENAGAKERJAAN.key]: {
              module: modules.BPJS_KETENAGAKERJAAN.key,
              features: [features.CREATE_DATA],
            },
          }}
        >
          <LoadingButton
            endIcon={<AddIcon />}
            loading={isLoadingButtonState.buttonCreate}
            loadingPosition="end"
            onClick={handleCreateBpjsKetenagakerjaan}
          >
            Create
          </LoadingButton>
        </AuthElement>
        <AuthElement
          moduleFeatures={{
            [modules.BPJS_KETENAGAKERJAAN.key]: {
              module: modules.BPJS_KETENAGAKERJAAN.key,
              features: [features.DELETE_DATA],
            },
          }}
        >
          <LoadingButton
            disabled={dataGridState.rowSelectionModel.length <= 0}
            endIcon={<DeleteIcon />}
            loading={isLoadingButtonState.buttonDelete}
            loadingPosition="end"
            onClick={() => {
              dialogContext.handleOpenDialog(
                () => handleDeleteBpjsKetenagakerjaan(),
                "Are you sure want to delete?",
                "Delete Confirmation"
              );
            }}
          >
            Delete
          </LoadingButton>
        </AuthElement>
        <AuthElement
          moduleFeatures={{
            [modules.BPJS_KETENAGAKERJAAN.key]: {
              module: modules.BPJS_KETENAGAKERJAAN.key,
              features: [
                features.EXPORT_DATA,
                features.EXPORT_TEMPLATE,
                features.IMPORT_DATA,
                features.IMPORT_TEMPLATE,
              ],
            },
          }}
        >
          <LoadingButton
            endIcon={<ExpandMoreIcon />}
            loading={
              isLoadingButtonState.buttonExportData ||
              isLoadingButtonState.buttonExportTemplate ||
              isLoadingButtonState.buttonImportData ||
              isLoadingButtonState.buttonImportTemplate
            }
            loadingPosition="end"
            onClick={handleOpenMenu}
          >
            More
          </LoadingButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            onClose={handleCloseMenu}
            open={open}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <AuthElement
              moduleFeatures={{
                [modules.BPJS_KETENAGAKERJAAN.key]: {
                  module: modules.BPJS_KETENAGAKERJAAN.key,
                  features: [features.IMPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  replaceBpjsKetenagakerjaanDataRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faUpload} />
                </ListItemIcon>
                <ListItemText>
                  Replace Data
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="bpjs-ketenagakerjaan-data"
                    onChange={(e) => {
                      dialogContext.handleOpenDialog(
                        () => handleReplaceBpjsKetenagakerjaanData(e),
                        "Your data will be lost, are you sure to continue?",
                        "Replace Data Confirmation"
                      );
                      handleCloseMenu();
                    }}
                    ref={replaceBpjsKetenagakerjaanDataRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.BPJS_KETENAGAKERJAAN.key]: {
                  module: modules.BPJS_KETENAGAKERJAAN.key,
                  features: [features.IMPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  importBpjsKetenagakerjaanDataRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileImport} />
                </ListItemIcon>
                <ListItemText>
                  Import Data
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="bpjs-ketenagakerjaan-data"
                    onChange={(e) => {
                      handleImportBpjsKetenagakerjaanData(e);
                      handleCloseMenu();
                    }}
                    ref={importBpjsKetenagakerjaanDataRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.BPJS_KETENAGAKERJAAN.key]: {
                  module: modules.BPJS_KETENAGAKERJAAN.key,
                  features: [features.EXPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleExportBpjsKetenagakerjaanData();
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileExport} />
                </ListItemIcon>
                <ListItemText>Export Data</ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.BPJS_KETENAGAKERJAAN.key]: {
                  module: modules.BPJS_KETENAGAKERJAAN.key,
                  features: [features.IMPORT_TEMPLATE],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  importBpjsKetenagakerjaanTemplateRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileArrowUp} />
                </ListItemIcon>
                <ListItemText>
                  Import Template
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="bpjs-ketenagakerjaan-template"
                    onChange={(e) => {
                      dialogContext.handleOpenDialog(
                        () => handleImportBpjsKetenagakerjaanTemplate(e),
                        "Your data will be lost, are you sure to continue?",
                        "Import Template Confirmation"
                      );
                      handleCloseMenu();
                    }}
                    ref={importBpjsKetenagakerjaanTemplateRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.BPJS_KETENAGAKERJAAN.key]: {
                  module: modules.BPJS_KETENAGAKERJAAN.key,
                  features: [features.EXPORT_TEMPLATE],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleExportBpjsKetenagakerjaanTemplate();
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </ListItemIcon>
                <ListItemText>Export Template</ListItemText>
              </MenuItem>
            </AuthElement>
          </Menu>
        </AuthElement>
      </Stack>
      <AdminDataGridPro
        columns={dataGridState.columns}
        dataGridState={dataGridState}
        processRowUpdate={handleUpdateBpjsKetenagakerjaan}
        setDataGridState={setDataGridState}
      />
    </>
  );
};

export default BpjsKetenagakerjaan;
