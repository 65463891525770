import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import ManualAttendanceWeek1 from "./ManualAttendanceWeek1";
import ManualAttendanceWeek2 from "./ManualAttendanceWeek2";
import ManualAttendanceWeek3 from "./ManualAttendanceWeek3";
import ManualAttendanceWeek4 from "./ManualAttendanceWeek4";

const ManualAttendance = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label={modules.MANUAL_ATTENDANCE_WEEK_1.value} value="0" />
            <Tab label={modules.MANUAL_ATTENDANCE_WEEK_2.value} value="1" />
            <Tab label={modules.MANUAL_ATTENDANCE_WEEK_3.value} value="2" />
            <Tab label={modules.MANUAL_ATTENDANCE_WEEK_4.value} value="3" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.MANUAL_ATTENDANCE_WEEK_1.key]: {
                module: modules.MANUAL_ATTENDANCE_WEEK_1.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <ManualAttendanceWeek1 />
          </AuthElement>
        </TabPanel>
        <TabPanel value="1">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
                module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <ManualAttendanceWeek2 />
          </AuthElement>
        </TabPanel>
        <TabPanel value="2">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.MANUAL_ATTENDANCE_WEEK_3.key]: {
                module: modules.MANUAL_ATTENDANCE_WEEK_3.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <ManualAttendanceWeek3 />
          </AuthElement>
        </TabPanel>
        <TabPanel value="3">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.MANUAL_ATTENDANCE_WEEK_4.key]: {
                module: modules.MANUAL_ATTENDANCE_WEEK_4.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <ManualAttendanceWeek4 />
          </AuthElement>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default ManualAttendance;
