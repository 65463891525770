import {
  faFileArrowDown,
  faFileArrowUp,
  faFileExport,
  faFileImport,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
} from "@mui/material";
import { parseISO } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AuthElement from "../../../components/AuthElement/AuthElement";
import AdminDataGridPro from "../../../components/admin/AdminDataGridPro";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import DialogContext from "../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { createAttendanceCorrection } from "../../../services/attendanceCorrection/createAttendanceCorrection";
import { deleteAttendanceCorrection } from "../../../services/attendanceCorrection/deleteAttendanceCorrection";
import { exportAttendanceCorrectionData } from "../../../services/attendanceCorrection/exportAttendanceCorrectionData";
import { exportAttendanceCorrectionTemplate } from "../../../services/attendanceCorrection/exportAttendanceCorrectionTemplate";
import { getAttendanceCorrection } from "../../../services/attendanceCorrection/getAttendanceCorrection";
import { getAttendanceCorrectionFilename } from "../../../services/attendanceCorrection/getAttendanceCorrectionFilename";
import { getAttendanceCorrectionTemplateFilename } from "../../../services/attendanceCorrection/getAttendanceCorrectionTemplateFilename";
import { importAttendanceCorrectionData } from "../../../services/attendanceCorrection/importAttendanceCorrectionData";
import { importAttendanceCorrectionTemplate } from "../../../services/attendanceCorrection/importAttendanceCorrectionTemplate";
import { replaceAttendanceCorrectionData } from "../../../services/attendanceCorrection/replaceAttendanceCorrectionData";
import { updateAttendanceCorrection } from "../../../services/attendanceCorrection/updateAttendanceCorrection";
import { getSingleSelectData } from "../../../services/singleSelect/getSingleSelectData";
import convertRulesToMUIDataType from "../../../utils/convertRulesToMUIDataType";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";
import getDataGridColumnWidth from "../../../utils/getDataGridColumnWidth";
import getSingleSelectFromRules from "../../../utils/getSingleSelectFromRules";
import isDataGridCellEditable from "../../../utils/isDataGridCellEditable";
import isValidDate from "../../../utils/isValidDate";

const AttendanceCorrection = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataGridState, setDataGridState] = useState({
    columns: [],
    columnVisibilityModel: {},
    filterModel: {},
    isLoading: false,
    pinnedRows: { top: [], bottom: [] },
    rowId: "attendance_correction_id",
    rowSelectionModel: [],
    rows: [],
    sortModel: [],
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
    buttonDelete: false,
    buttonExportData: false,
    buttonExportTemplate: false,
    buttonImportData: false,
    buttonImportTemplate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const importAttendanceCorrectionDataRef = useRef();
  const importAttendanceCorrectionTemplateRef = useRef();
  const open = Boolean(anchorEl);
  const replaceAttendanceCorrectionDataRef = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const fetchAttendanceCorrectionData = async () => {
    try {
      // get drop down value
      let singleSelectData = await fetchSingleSelectData();

      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getAttendanceCorrection();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        initAttendanceCorrectionDataGrid(
          res.payload.query,
          singleSelectData,
          res.templateData
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchSingleSelectData = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getSingleSelectData(
        modules.ATTENDANCE_CORRECTION.key.toLowerCase()
      );

      if (res.status === 200) {
        return res.payload;
      } else {
        return [];
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCreateAttendanceCorrection = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      let res = await getAttendanceCorrectionTemplateFilename();

      if (res.status !== 200) {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await createAttendanceCorrection({
        newAttendanceCorrectionData: dataGridState.rows[0],
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      // if success then fetch
      if (res.status === 200) {
        fetchAttendanceCorrectionData();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleDeleteAttendanceCorrection = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonDelete: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await deleteAttendanceCorrection({
        selectedAttendanceCorrectionID: dataGridState.rowSelectionModel,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      // if success then fetch
      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rowSelectionModel: [],
        }));

        fetchAttendanceCorrectionData();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonDelete: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportAttendanceCorrectionData = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let res = await getAttendanceCorrectionTemplateFilename();

      if (res.status !== 200) {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      let filename = null;

      res = await getAttendanceCorrectionFilename();

      if (res.status === 200) {
        filename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportAttendanceCorrectionData(
        {
          columnVisibilityModel: dataGridState.columnVisibilityModel,
          filterModel: dataGridState.filterModel,
          sortModel: dataGridState.sortModel,
        },
        { responseType: "arraybuffer" }
      );

      const link = document.createElement("a");
      const tempFilename = filename;
      link.setAttribute("download", tempFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Data Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportAttendanceCorrectionTemplate = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportTemplate: true,
      }));
      setIsLoadingLinearProgress(true);

      let templateFilename = null;

      let res = await getAttendanceCorrectionTemplateFilename();

      if (res.status === 200) {
        templateFilename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportAttendanceCorrectionTemplate({
        responseType: "arraybuffer",
      });

      const link = document.createElement("a");
      const tempTemplateFilename = templateFilename;
      link.setAttribute("download", tempTemplateFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Template Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportTemplate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleImportAttendanceCorrectionData = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "attendanceCorrectionData",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await importAttendanceCorrectionData(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchAttendanceCorrectionData();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("attendance-correction-data")) {
        document.getElementById("attendance-correction-data").value = "";
      }
    }
  };

  const handleImportAttendanceCorrectionTemplate = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportTemplate: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "attendanceCorrectionTemplate",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await importAttendanceCorrectionTemplate(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchAttendanceCorrectionData();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportTemplate: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("attendance-correction-template")) {
        document.getElementById("attendance-correction-template").value = "";
      }
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReplaceAttendanceCorrectionData = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "attendanceCorrectionData",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await replaceAttendanceCorrectionData(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchAttendanceCorrectionData();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("attendance-correction-data")) {
        document.getElementById("attendance-correction-data").value = "";
      }
    }
  };

  const handleUpdateAttendanceCorrection = async (newRow) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      // if update insert row
      if (newRow.attendance_correction_id === 0) {
        const tempNewAttendanceCorrectionData = dataGridState.rows.map(
          (currentState) => {
            return currentState.attendance_correction_id ===
              newRow.attendance_correction_id
              ? newRow
              : currentState;
          }
        );

        setDataGridState((prevState) => ({
          ...prevState,
          rows: tempNewAttendanceCorrectionData,
        }));
      } else {
        // update data at DB
        try {
          setDataGridState((prevState) => ({
            ...prevState,
            isLoading: true,
          }));
          setIsLoadingLinearProgress(true);

          const res = await updateAttendanceCorrection({
            newAttendanceCorrectionData: newRow,
          });

          snackbarContext.handleOpenSnackbar(res.message, res.status);

          fetchAttendanceCorrectionData();
        } catch (error) {
          snackbarContext.handleOpenSnackbar(
            generateSnackbarErrorMessage(error),
            "error"
          );
          throw error;
        } finally {
          setDataGridState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          setIsLoadingLinearProgress(false);
        }
      }
    } catch (error) {
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const initAttendanceCorrectionDataGrid = (
    data,
    singleSelectData,
    templateData
  ) => {
    const gridColumns = [];
    const gridRows = [];
    const insertRow = {};

    // init column & insert row
    for (let i = 0; i < templateData.columns.length; i++) {
      let temp = {
        description:
          convertRulesToMUIDataType(templateData.rules[i]) === "date"
            ? "mm/dd/yyyy"
            : null,
        // if not id or formula then can edit
        editable: isDataGridCellEditable(templateData.rules[i]),
        field: templateData.fields[i],
        headerName: templateData.columns[i],
        minWidth: getDataGridColumnWidth(templateData.rules[i]),
        type: convertRulesToMUIDataType(templateData.rules[i]),
      };

      if (convertRulesToMUIDataType(templateData.rules[i]) === "singleSelect") {
        temp.valueOptions =
          singleSelectData[getSingleSelectFromRules(templateData.rules[i])];
      }

      gridColumns.push(temp);

      if (templateData.fields[i] === "attendance_correction_id") {
        insertRow[templateData.fields[i]] = 0;
      } else if (
        // avoid error (mui single select value cannot null)
        convertRulesToMUIDataType(templateData.rules[i]) === "singleSelect"
      ) {
        insertRow[templateData.fields[i]] = "";
      } else {
        insertRow[templateData.fields[i]] = null;
      }
    }

    gridRows.push(insertRow);

    // init row
    for (let i = 0; i < data.length; i++) {
      const keys = Object.keys(data[i]);

      for (let j = 0; j < keys.length; j++) {
        if (convertRulesToMUIDataType(templateData.rules[j]) === "date") {
          data[i][keys[j]] = parseISO(data[i][keys[j]]);

          if (!isValidDate(data[i][keys[j]])) {
            data[i][keys[j]] = "";
          }
        }
      }

      gridRows.push(data[i]);
    }

    setDataGridState((prevState) => ({
      ...prevState,
      columns: gridColumns,
      rows: gridRows,
    }));
  };

  useEffect(() => {
    fetchAttendanceCorrectionData();

    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={"flex-end"}
        spacing={2}
        sx={{ mb: 2 }}
      >
        <AuthElement
          moduleFeatures={{
            [modules.ATTENDANCE_CORRECTION.key]: {
              module: modules.ATTENDANCE_CORRECTION.key,
              features: [features.CREATE_DATA],
            },
          }}
        >
          <LoadingButton
            endIcon={<AddIcon />}
            loading={isLoadingButtonState.buttonCreate}
            loadingPosition="end"
            onClick={handleCreateAttendanceCorrection}
          >
            Create
          </LoadingButton>
        </AuthElement>
        <AuthElement
          moduleFeatures={{
            [modules.ATTENDANCE_CORRECTION.key]: {
              module: modules.ATTENDANCE_CORRECTION.key,
              features: [features.DELETE_DATA],
            },
          }}
        >
          <LoadingButton
            disabled={dataGridState.rowSelectionModel.length <= 0}
            endIcon={<DeleteIcon />}
            loading={isLoadingButtonState.buttonDelete}
            loadingPosition="end"
            onClick={() => {
              dialogContext.handleOpenDialog(
                () => handleDeleteAttendanceCorrection(),
                "Are you sure want to delete?",
                "Delete Confirmation"
              );
            }}
          >
            Delete
          </LoadingButton>
        </AuthElement>
        <AuthElement
          moduleFeatures={{
            [modules.ATTENDANCE_CORRECTION.key]: {
              module: modules.ATTENDANCE_CORRECTION.key,
              features: [
                features.EXPORT_DATA,
                features.EXPORT_TEMPLATE,
                features.IMPORT_DATA,
                features.IMPORT_TEMPLATE,
              ],
            },
          }}
        >
          <LoadingButton
            endIcon={<ExpandMoreIcon />}
            loading={
              isLoadingButtonState.buttonExportData ||
              isLoadingButtonState.buttonExportTemplate ||
              isLoadingButtonState.buttonImportData ||
              isLoadingButtonState.buttonImportTemplate
            }
            loadingPosition="end"
            onClick={handleOpenMenu}
          >
            More
          </LoadingButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            onClose={handleCloseMenu}
            open={open}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <AuthElement
              moduleFeatures={{
                [modules.ATTENDANCE_CORRECTION.key]: {
                  module: modules.ATTENDANCE_CORRECTION.key,
                  features: [features.IMPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  replaceAttendanceCorrectionDataRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faUpload} />
                </ListItemIcon>
                <ListItemText>
                  Replace Data
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="attendance-correction-data"
                    onChange={(e) => {
                      dialogContext.handleOpenDialog(
                        () => handleReplaceAttendanceCorrectionData(e),
                        "Your data will be lost, are you sure to continue?",
                        "Replace Data Confirmation"
                      );
                      handleCloseMenu();
                    }}
                    ref={replaceAttendanceCorrectionDataRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.ATTENDANCE_CORRECTION.key]: {
                  module: modules.ATTENDANCE_CORRECTION.key,
                  features: [features.IMPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  importAttendanceCorrectionDataRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileImport} />
                </ListItemIcon>
                <ListItemText>
                  Import Data
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="attendance-correction-data"
                    onChange={(e) => {
                      handleImportAttendanceCorrectionData(e);
                      handleCloseMenu();
                    }}
                    ref={importAttendanceCorrectionDataRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.ATTENDANCE_CORRECTION.key]: {
                  module: modules.ATTENDANCE_CORRECTION.key,
                  features: [features.EXPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleExportAttendanceCorrectionData();
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileExport} />
                </ListItemIcon>
                <ListItemText>Export Data</ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.ATTENDANCE_CORRECTION.key]: {
                  module: modules.ATTENDANCE_CORRECTION.key,
                  features: [features.IMPORT_TEMPLATE],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  importAttendanceCorrectionTemplateRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileArrowUp} />
                </ListItemIcon>
                <ListItemText>
                  Import Template
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="attendance-correction-template"
                    onChange={(e) => {
                      dialogContext.handleOpenDialog(
                        () => handleImportAttendanceCorrectionTemplate(e),
                        "Your data will be lost, are you sure to continue?",
                        "Import Template Confirmation"
                      );
                      handleCloseMenu();
                    }}
                    ref={importAttendanceCorrectionTemplateRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.ATTENDANCE_CORRECTION.key]: {
                  module: modules.ATTENDANCE_CORRECTION.key,
                  features: [features.EXPORT_TEMPLATE],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleExportAttendanceCorrectionTemplate();
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </ListItemIcon>
                <ListItemText>Export Template</ListItemText>
              </MenuItem>
            </AuthElement>
          </Menu>
        </AuthElement>
      </Stack>
      <AdminDataGridPro
        columns={dataGridState.columns}
        dataGridState={dataGridState}
        processRowUpdate={handleUpdateAttendanceCorrection}
        setDataGridState={setDataGridState}
      />
    </Paper>
  );
};

export default AttendanceCorrection;
