import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { BASE_URL_BE } from "../../../constants/url";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { getProfilePicture } from "../../../services/manageProfile/getProfilePicture";
import { updateProfilePicture } from "../../../services/manageProfile/updateProfilePicture";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";
import styles from "./ProfilePicture.module.scss";

const ManageProfile = () => {
  const { handleSubmit } = useForm();
  const [oldProfilePicturePreview, setOldProfilePicturePreview] = useState([]);
  const [profilePicture, setProfilePicture] = useState([]);
  const [profilePicturePreview, setProfilePicturePreview] = useState([]);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const profilePictureRef = useRef([]);
  const snackbarContext = useContext(SnackbarContext);

  const fetchProfilePicture = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getProfilePicture();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setOldProfilePicturePreview([
          `${BASE_URL_BE}/uploads/${localStorage.getItem(
            "company_code"
          )}/user/${res.payload.profile_picture_name}`,
        ]);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleRemoveProfilePicture = (index) => {
    // update uploaded image
    let tempProfilePicture = Array.from(profilePicture);
    tempProfilePicture.splice(index, 1);

    let list = new DataTransfer();
    tempProfilePicture.forEach((element) => {
      list.items.add(element);
    });

    setProfilePicture(list.files);

    // update ref
    let tempProfilePictureRef = Array.from(profilePictureRef.current.files);
    tempProfilePictureRef.splice(index, 1);

    let listRef = new DataTransfer();
    tempProfilePictureRef.forEach((element) => {
      listRef.items.add(element);
    });

    profilePictureRef.current.files = listRef.files;
  };

  const handleUpdateProfilePicture = async (data) => {
    if (profilePicture.length <= 0) {
      snackbarContext.handleOpenSnackbar("Please choose image", 500);
      return;
    }

    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();

      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          formData.append(key, data[key]);
        }
      }

      // append images
      for (let i = 0; i < profilePicture.length; i++) {
        let element = profilePicture[i];
        formData.append("photo", element);
      }

      const res = await updateProfilePicture(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchProfilePicture();
        setProfilePicture([]);
        localStorage.setItem("user_profile_picture_name", res.payload);
        profilePictureRef.current.files = new DataTransfer().files;
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // profile picture preview
  useEffect(() => {
    const fileReaders = [];
    let isCancel = false;
    const tempImagePreview = [];

    if (profilePicture.length) {
      Object.values(profilePicture).forEach((file) => {
        const fileReader = new FileReader();

        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            tempImagePreview.push(result);
          }
          if (!isCancel) {
            setProfilePicturePreview(tempImagePreview);
          }
        };
        fileReader.readAsDataURL(file);
      });
    } else {
      setProfilePicturePreview([]);
    }

    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [profilePicture]);

  useEffect(() => {
    fetchProfilePicture();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(handleUpdateProfilePicture)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Gambar Lama</Typography>
          </Grid>
          {oldProfilePicturePreview.length <= 0 ? (
            <Grid item xs={12}>
              <em>(Belum ada gambar)</em>
            </Grid>
          ) : (
            oldProfilePicturePreview.map((element, index) => {
              return (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <div
                    className={
                      styles["profile-picture__image-preview-container"]
                    }
                  >
                    <img
                      alt={`profile-pic-preview-${index}`}
                      className={styles["profile-picture__image-preview"]}
                      src={element}
                    />
                  </div>
                </Grid>
              );
            })
          )}
          <Grid item xs={12}>
            <Stack alignItems="center" direction="row" spacing={3}>
              <span>
                <strong>Unggah Gambar</strong>
              </span>
              <Tooltip placement="right" title="Unggah Gambar">
                <IconButton color="primary" component="label">
                  <input
                    accept="image/*"
                    hidden
                    onChange={(e) => setProfilePicture(e.target.files)}
                    ref={profilePictureRef}
                    type="file"
                  />
                  <PhotoCameraIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          {profilePicturePreview.length <= 0 ? (
            <Grid item xs={12}>
              <em>(Belum ada gambar)</em>
            </Grid>
          ) : (
            profilePicturePreview.map((element, index) => {
              return (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <div
                    className={
                      styles["profile-picture__image-preview-container"]
                    }
                  >
                    <img
                      alt={`profile-pic-preview-${index}`}
                      className={styles["profile-picture__image-preview"]}
                      src={element}
                    />
                    <Tooltip title="Delete">
                      <IconButton
                        className={
                          styles["profile-picture__delete-image-preview"]
                        }
                        color="error"
                        component="label"
                        onClick={() => handleRemoveProfilePicture(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              );
            })
          )}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                endIcon={<SaveIcon />}
                loading={isLoadingButtonState.buttonUpdate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Simpan
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ManageProfile;
