import { Button, ThemeProvider } from "@mui/material";
import Lottie from "lottie-react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../../contexts/ThemeContextProvider";
import styles from "./NotFound.module.scss";
import notFound from "./notFound.json";

const NotFound = () => {
  const navigate = useNavigate();
  const themeContext = useContext(ThemeContext);

  return (
    <ThemeProvider theme={themeContext.theme}>
      <div className={styles["not-found__container"]}>
        <Lottie
          animationData={notFound}
          className={styles["not-found__animation"]}
          loop={true}
        />
        <Button
          className={styles["not-found__button"]}
          onClick={() => navigate("/")}
          variant="outlined"
        >
          Back
        </Button>
      </div>
    </ThemeProvider>
  );
};

export default NotFound;
