import { postMethodToken } from "../../utils/httpMethod/postMethodToken";

export const importWorkAgreementTemplate = async (data) => {
  const res = await postMethodToken(
    "admin/work_agreement/import_template",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
