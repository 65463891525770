export const attendanceCalculationColumn = [
  { field: "attendance_calculation_id", headerName: "ID", minWidth: 70 },
  {
    field: "nip",
    headerName: "NIP",
    minWidth: 250,
  },
  {
    field: "nama",
    headerName: "Nama",
    minWidth: 250,
  },
  {
    field: "departemen",
    headerName: "Departemen",
    minWidth: 250,
  },
  {
    field: "tanggal_mulai_kerja",
    headerName: "Tanggal Mulai kerja",
    minWidth: 250,
    type: "date",
  },
  {
    field: "tanggal_berakhir_kerja",
    headerName: "Tanggal Berakhir kerja",
    minWidth: 250,
    type: "date",
  },
  {
    field: "status_db",
    headerName: "Status DB",
    minWidth: 250,
  },
  {
    field: "hk_pagu",
    headerName: "HK Pagu",
    minWidth: 250,
  },
  {
    field: "status_hk",
    headerName: "Status HK",
    minWidth: 250,
  },
  {
    field: "jenis_perhitungan_absensi",
    headerName: "Jenis Perhitungan Absensi",
    minWidth: 250,
  },
  {
    field: "hk_kalender",
    headerName: "HK Kalender",
    minWidth: 250,
  },
  {
    field: "hk_kalender_tanggal_merah",
    headerName: "HK Kalender Minus Tanggal Merah",
    minWidth: 250,
  },
  {
    field: "total_terlambat",
    headerName: "Total Terlambat",
    minWidth: 250,
  },
  {
    field: "total_pulang_cepat",
    headerName: "Total Pulang Cepat",
    minWidth: 250,
  },
  {
    field: "total_tidak_hadir",
    headerName: "Total Tidak Hadir",
    minWidth: 250,
  },
  {
    field: "total_absen",
    headerName: "Total Absen",
    minWidth: 250,
  },
  {
    field: "total_hadir",
    headerName: "Total Hadir",
    minWidth: 250,
  },
  {
    field: "hk_real",
    headerName: "HK Real",
    minWidth: 250,
  },
  {
    field: "gp_bulan",
    headerName: "Gaji Pokok per Bulan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gp_hari",
    headerName: "Gaji Pokok per Hari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gp_jam",
    headerName: "Gaji Pokok per Jam",
    minWidth: 250,
    type: "number",
  },
  {
    field: "tt_bulan",
    headerName: "Tunjangan Tetap per Bulan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "tt_hari",
    headerName: "Tunjangan Tetap per Hari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "tt_jam",
    headerName: "Tunjangan Tetap per Jam",
    minWidth: 250,
    type: "number",
  },
  {
    field: "ttt_bulan",
    headerName: "Tunjangan Tidak Tetap per Bulan",
    minWidth: 300,
    type: "number",
  },
  {
    field: "ttt_hari",
    headerName: "Tunjangan Tidak Tetap per Hari",
    minWidth: 300,
    type: "number",
  },
  {
    field: "ttt_jam",
    headerName: "Tunjangan Tidak Tetap per Jam",
    minWidth: 300,
    type: "number",
  },
  {
    field: "gaji_sebelum_potongan",
    headerName: "Gaji Sebelum Potongan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "potongan_terlambat",
    headerName: "Potongan Terlambat",
    minWidth: 250,
    type: "number",
  },
  {
    field: "potongan_pulang_cepat",
    headerName: "Potongan Pulang Cepat",
    minWidth: 250,
    type: "number",
  },
  {
    field: "potongan_tidak_hadir",
    headerName: "Potongan Tidak Hadir",
    minWidth: 250,
    type: "number",
  },
  {
    field: "potongan_total_absen",
    headerName: "Potongan Total Absen",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_potongan",
    headerName: "Total Potongan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_setelah_potongan",
    headerName: "Gaji Setelah Potongan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_jam_lembur_1",
    headerName: "Total Jam Lembur 1",
    minWidth: 250,
  },
  {
    field: "total_jam_lembur_2",
    headerName: "Total Jam Lembur 2",
    minWidth: 250,
  },
  {
    field: "total_jam_lembur_3",
    headerName: "Total Jam Lembur 3",
    minWidth: 250,
  },
  {
    field: "total_jam_lembur_4",
    headerName: "Total Jam Lembur 4",
    minWidth: 250,
  },
  {
    field: "nominal_lembur_1",
    headerName: "Nominal Lembur 1",
    minWidth: 250,
    type: "number",
  },
  {
    field: "nominal_lembur_2",
    headerName: "Nominal Lembur 2",
    minWidth: 250,
    type: "number",
  },
  {
    field: "nominal_lembur_3",
    headerName: "Nominal Lembur 3",
    minWidth: 250,
    type: "number",
  },
  {
    field: "nominal_lembur_4",
    headerName: "Nominal Lembur 4",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_lembur",
    headerName: "Total Lembur",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_setelah_potongan_dan_lembur",
    headerName: "Gaji Setelah Potongan dan Lembur",
    minWidth: 250,
    type: "number",
  },
];
