import { postMethodToken } from "../../utils/httpMethod/postMethodToken";

export const replaceAttendanceReportData = async (data) => {
  const res = await postMethodToken(
    "admin/attendance_report/import_data?isReplace=true",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
