import axios from "axios";
import { BASE_URL_BE } from "../../constants/url";
import cookie from "../cookie";

const myAxiosToken = axios.create({
  baseURL: BASE_URL_BE,
  withCredentials: true,
});

myAxiosToken.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

myAxiosToken.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      try {
        const res = await axios.post("token/verify_token", null, {
          baseURL: BASE_URL_BE,
          withCredentials: true,
        });

        if (res.status === 200) {
          return axios(error.config);
        }
      } catch (error) {
        cookie.deleteAllCookies();
        window.location.reload();
      }
    }

    return Promise.reject(error);
  }
);

export default myAxiosToken;
