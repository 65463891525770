import { postMethodToken } from "../../utils/httpMethod/postMethodToken";

export const exportSalarySlipReportData = async (data, config) => {
  const res = await postMethodToken(
    "admin/salary_slip_report/export_data",
    data,
    config
  );
  return res;
};
