import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import Pph21Progressive from "./Pph21Progressive";
import Pph21Ter from "./Pph21Ter";
import PtkpProgressive from "./PtkpProgressive";
import PtkpTer from "./PtkpTer";

const CountryTax = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label={modules.PTKP_TER.value} value="0" />
            <Tab label={modules.PTKP_PROGRESSIVE.value} value="1" />
            <Tab label={modules.PPH21_TER.value} value="2" />
            <Tab label={modules.PPH21_PROGRESSIVE.value} value="3" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.PTKP_TER.key]: {
                module: modules.PTKP_TER.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <PtkpTer />
          </AuthElement>
        </TabPanel>
        <TabPanel value="1">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.PTKP_PROGRESSIVE.key]: {
                module: modules.PTKP_PROGRESSIVE.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <PtkpProgressive />
          </AuthElement>
        </TabPanel>
        <TabPanel value="2">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.PPH21_TER.key]: {
                module: modules.PPH21_TER.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <Pph21Ter />
          </AuthElement>
        </TabPanel>
        <TabPanel value="3">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.PPH21_PROGRESSIVE.key]: {
                module: modules.PPH21_PROGRESSIVE.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <Pph21Progressive />
          </AuthElement>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default CountryTax;
