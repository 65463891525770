import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { manageUserRoleSchema } from "../../../../schemas/manageUserRoleSchema";
import { getUserRoleDetail } from "../../../../services/manageUserRole/getUserRoleDetail";
import { updateUserRole } from "../../../../services/manageUserRole/updateUserRole";
import { getModuleFeatures } from "../../../../services/moduleFeatures/getModuleFeatures";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const ManageUserRoleDetail = () => {
  const { id } = useParams();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(manageUserRoleSchema),
  });
  const [
    autocompleteUserRoleSelectedState,
    setAutocompleteUserRoleSelectedState,
  ] = useState([]);
  const [autocompleteUserRoleState, setAutocompleteUserRoleState] = useState(
    []
  );
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const snackbarContext = useContext(SnackbarContext);

  const fetchModuleFeatures = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getModuleFeatures();

      if (res.status === 200) {
        initUserRoleModuleList(res.payload);
      }
    } catch (error) {
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchUserRoleDetail = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getUserRoleDetail(id);

      if (res.status === 200) {
        setValue("name", res.payload.name);
        initUserRoleJson(res.payload.json);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const generateUserRoleJson = (data) => {
    let userRoleKeys = Object.keys(data);
    let tempUserRoleJson = [];

    for (let i = 0; i < userRoleKeys.length; i++) {
      tempUserRoleJson.push({
        module: userRoleKeys[i],
        features: data[userRoleKeys[i]].map((moduleFeature) => {
          return moduleFeature.feature;
        }),
      });
    }

    let result = tempUserRoleJson;

    return JSON.stringify(result);
  };

  const handleChangeAutocompleteUserRole = (data, value) => {
    let tempAutocompleteUserRoleSelectedState = {
      ...autocompleteUserRoleSelectedState,
    };
    tempAutocompleteUserRoleSelectedState[data.key] = value;
    setAutocompleteUserRoleSelectedState(tempAutocompleteUserRoleSelectedState);
  };

  const handleSelectAllFeature = (data) => {
    let tempAutocompleteUserRoleSelectedState = {
      ...autocompleteUserRoleSelectedState,
    };
    tempAutocompleteUserRoleSelectedState[data.key] = data.options;
    setAutocompleteUserRoleSelectedState(tempAutocompleteUserRoleSelectedState);
  };

  const handleUpdateUserRole = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.id = parseInt(id);
      data.json = generateUserRoleJson(autocompleteUserRoleSelectedState);

      const res = await updateUserRole(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchUserRoleDetail();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const initUserRoleJson = (data) => {
    let tempAutocompleteUserRoleSelectedState = {
      ...autocompleteUserRoleSelectedState,
    };

    const userRoleJsonValue = JSON.parse(data);

    for (let i = 0; i < userRoleJsonValue.length; i++) {
      const userRoleJson = userRoleJsonValue[i];

      tempAutocompleteUserRoleSelectedState[userRoleJson.module] =
        userRoleJson.features.map((feature) => {
          return { feature: feature };
        });
    }

    setAutocompleteUserRoleSelectedState(tempAutocompleteUserRoleSelectedState);
  };

  const initUserRoleModuleList = (data) => {
    let tempAutocompleteUserRoleSelectedState = [];
    let tempAutocompleteUserRoleState = [];

    for (let i = 0; i < data.length; i++) {
      const moduleFeature = data[i];

      tempAutocompleteUserRoleSelectedState[moduleFeature.module] = [];
      tempAutocompleteUserRoleState.push({
        key: moduleFeature.module,
        options: moduleFeature.features.map((feature) => {
          return { feature: feature };
        }),
        value: moduleFeature.module_name,
      });
    }

    setAutocompleteUserRoleSelectedState(tempAutocompleteUserRoleSelectedState);
    setAutocompleteUserRoleState(tempAutocompleteUserRoleState);
  };

  useEffect(() => {
    fetchModuleFeatures();
    fetchUserRoleDetail();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(handleUpdateUserRole)}>
        {isLoadingLinearProgress ? (
          <Loader />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                render={({
                  field: { onChange, ref, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    error={!!error}
                    fullWidth
                    helperText={error?.message}
                    inputRef={ref}
                    label="Nama Peran"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Daftar Modul</h2>
            </Grid>
            {autocompleteUserRoleState.map((module, index) => {
              return (
                <Grid item key={index} xl={3} lg={4} sm={6} xs={12}>
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <Autocomplete
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.feature}
                      isOptionEqualToValue={(option, value) =>
                        option.feature === value.feature
                      }
                      limitTags={3}
                      multiple
                      onChange={(_, value) =>
                        handleChangeAutocompleteUserRole(module, value)
                      }
                      options={module.options}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.feature}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={module.value}
                          placeholder="Pilih Fitur"
                        />
                      )}
                      sx={{ flex: 1 }}
                      value={
                        autocompleteUserRoleSelectedState[module.key] ?? []
                      }
                    />
                    <Tooltip title="Pilih Semua Fitur">
                      <IconButton
                        color="primary"
                        component="label"
                        onClick={() => handleSelectAllFeature(module)}
                      >
                        <DoneAllIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end">
                <LoadingButton
                  endIcon={<SaveIcon />}
                  loading={isLoadingButtonState.buttonUpdate}
                  loadingPosition="end"
                  sx={{ mt: 2 }}
                  type="submit"
                >
                  Update
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        )}
      </form>
    </Paper>
  );
};

export default ManageUserRoleDetail;
