import { postMethodToken } from "../../utils/httpMethod/postMethodToken";

export const exportIdentityCardData = async (data, config) => {
  const res = await postMethodToken(
    "admin/identity_card/export_data",
    data,
    config
  );
  return res;
};
