import React from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import cookie from "../utils/cookie";

export const AdminMiddleware = () => {
  const outletContext = useOutletContext();
  const isLoggedIn = cookie.getCookie("is_logged_in");

  return isLoggedIn ? (
    <Outlet context={outletContext} />
  ) : (
    <Navigate to="/login" />
  );
};
