import {
  faFileArrowDown,
  faFileArrowUp,
  faFileExport,
  faFileImport,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
} from "@mui/material";
import { parseISO } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AuthElement from "../../../components/AuthElement/AuthElement";
import AdminDataGrid from "../../../components/admin/AdminDataGrid";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import DialogContext from "../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { getSingleSelectData } from "../../../services/singleSelect/getSingleSelectData";
import { createTransactionProofReport } from "../../../services/transactionProofReport/createTransactionProofReport";
import { deleteTransactionProofReport } from "../../../services/transactionProofReport/deleteTransactionProofReport";
import { exportTransactionProofReportData } from "../../../services/transactionProofReport/exportTransactionProofReportData";
import { exportTransactionProofReportTemplate } from "../../../services/transactionProofReport/exportTransactionProofReportTemplate";
import { getTransactionProofReport } from "../../../services/transactionProofReport/getTransactionProofReport";
import { getTransactionProofReportFilename } from "../../../services/transactionProofReport/getTransactionProofReportFilename";
import { getTransactionProofReportTemplateFilename } from "../../../services/transactionProofReport/getTransactionProofReportTemplateFilename";
import { importTransactionProofReportData } from "../../../services/transactionProofReport/importTransactionProofReportData";
import { importTransactionProofReportTemplate } from "../../../services/transactionProofReport/importTransactionProofReportTemplate";
import { replaceTransactionProofReportData } from "../../../services/transactionProofReport/replaceTransactionProofReportData";
import { updateTransactionProofReport } from "../../../services/transactionProofReport/updateTransactionProofReport";
import convertRulesToMUIDataType from "../../../utils/convertRulesToMUIDataType";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";
import getDataGridColumnWidth from "../../../utils/getDataGridColumnWidth";
import getSingleSelectFromRules from "../../../utils/getSingleSelectFromRules";
import isDataGridCellEditable from "../../../utils/isDataGridCellEditable";
import isValidDate from "../../../utils/isValidDate";

const TransactionProofReport = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataGridState, setDataGridState] = useState({
    columns: [],
    columnVisibilityModel: {},
    filterModel: [],
    isLoading: false,
    pageIndex: 0,
    rowCount: 0,
    rows: [],
    rowsPerPage: 10,
    rowSelectionModel: [],
    sortModel: [],
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
    buttonDelete: false,
    buttonExportData: false,
    buttonExportTemplate: false,
    buttonImportData: false,
    buttonImportTemplate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const importTransactionProofReportDataRef = useRef();
  const importTransactionProofReportTemplateRef = useRef();
  const open = Boolean(anchorEl);
  const replaceTransactionProofReportDataRef = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const fetchTransactionProofReportData = async (
    filterModel,
    pageIndex,
    rowsPerPage,
    sortModel
  ) => {
    try {
      // get drop down value
      let singleSelectData = await fetchSingleSelectData();

      setDataGridState((prevState) => ({
        ...prevState,
        filterModel: filterModel,
        isLoading: true,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getTransactionProofReport({
        filterModel: filterModel,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        initTransactionProofReportDataGrid(
          res.payload.query,
          res.payload.rowCount,
          singleSelectData,
          res.templateData
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchSingleSelectData = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getSingleSelectData(
        modules.TRANSACTION_PROOF_REPORT.key.toLowerCase()
      );

      if (res.status === 200) {
        return res.payload;
      } else {
        return [];
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleChangeColumnVisibilityModel = (newColumnVisibilityModel) => {
    setDataGridState((prevState) => ({
      ...prevState,
      columnVisibilityModel: newColumnVisibilityModel,
    }));
  };

  const handleChangeRowSelectionModel = (newRowSelectionModel) => {
    setDataGridState((prevState) => ({
      ...prevState,
      rowSelectionModel: newRowSelectionModel,
    }));
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCreateTransactionProofReport = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      let res = await getTransactionProofReportTemplateFilename();

      if (res.status !== 200) {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await createTransactionProofReport({
        newTransactionProofReportData: dataGridState.rows[0],
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      // if success then fetch
      if (res.status === 200) {
        fetchTransactionProofReportData(
          dataGridState.filterModel,
          dataGridState.pageIndex,
          dataGridState.rowsPerPage,
          dataGridState.sortModel
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleDeleteTransactionProofReport = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonDelete: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await deleteTransactionProofReport({
        selectedTransactionProofReportID: dataGridState.rowSelectionModel,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      // if success then fetch
      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rowSelectionModel: [],
        }));

        fetchTransactionProofReportData(
          dataGridState.filterModel,
          dataGridState.pageIndex,
          dataGridState.rowsPerPage,
          dataGridState.sortModel
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonDelete: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportTransactionProofReportData = async (
    columnVisibilityModel,
    filterModel,
    sortModel
  ) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let res = await getTransactionProofReportTemplateFilename();

      if (res.status !== 200) {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      let filename = null;

      res = await getTransactionProofReportFilename();

      if (res.status === 200) {
        filename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportTransactionProofReportData(
        {
          columnVisibilityModel: columnVisibilityModel,
          filterModel: filterModel,
          sortModel: sortModel,
        },
        { responseType: "arraybuffer" }
      );

      const link = document.createElement("a");
      const tempFilename = filename;
      link.setAttribute("download", tempFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Data Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportTransactionProofReportTemplate = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportTemplate: true,
      }));
      setIsLoadingLinearProgress(true);

      let templateFilename = null;

      let res = await getTransactionProofReportTemplateFilename();

      if (res.status === 200) {
        templateFilename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportTransactionProofReportTemplate({
        responseType: "arraybuffer",
      });

      const link = document.createElement("a");
      const tempTemplateFilename = templateFilename;
      link.setAttribute("download", tempTemplateFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Template Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportTemplate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleImportTransactionProofReportData = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "transactionProofReportData",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await importTransactionProofReportData(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchTransactionProofReportData(
        dataGridState.filterModel,
        dataGridState.pageIndex,
        dataGridState.rowsPerPage,
        dataGridState.sortModel
      );
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("transaction-proof-report-data")) {
        document.getElementById("transaction-proof-report-data").value = "";
      }
    }
  };

  const handleImportTransactionProofReportTemplate = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportTemplate: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "transactionProofReportTemplate",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await importTransactionProofReportTemplate(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchTransactionProofReportData(
        dataGridState.filterModel,
        dataGridState.pageIndex,
        dataGridState.rowsPerPage,
        dataGridState.sortModel
      );
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportTemplate: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("transaction-proof-report-template")) {
        document.getElementById("transaction-proof-report-template").value = "";
      }
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReplaceTransactionProofReportData = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "transactionProofReportData",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await replaceTransactionProofReportData(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchTransactionProofReportData(
        dataGridState.filterModel,
        dataGridState.pageIndex,
        dataGridState.rowsPerPage,
        dataGridState.sortModel
      );
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("transaction-proof-report-data")) {
        document.getElementById("transaction-proof-report-data").value = "";
      }
    }
  };

  const handleUpdateTransactionProofReport = async (newRow) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      // if update insert row
      if (newRow.transaction_proof_report_id === 0) {
        const tempNewTransactionProofReportData = dataGridState.rows.map(
          (currentState) => {
            return currentState.transaction_proof_report_id ===
              newRow.transaction_proof_report_id
              ? newRow
              : currentState;
          }
        );

        setDataGridState((prevState) => ({
          ...prevState,
          rows: tempNewTransactionProofReportData,
        }));
      } else {
        // update data at DB
        try {
          setDataGridState((prevState) => ({
            ...prevState,
            isLoading: true,
          }));
          setIsLoadingLinearProgress(true);

          const res = await updateTransactionProofReport({
            newTransactionProofReportData: newRow,
          });

          snackbarContext.handleOpenSnackbar(res.message, res.status);

          fetchTransactionProofReportData(
            dataGridState.filterModel,
            dataGridState.pageIndex,
            dataGridState.rowsPerPage,
            dataGridState.sortModel
          );
        } catch (error) {
          snackbarContext.handleOpenSnackbar(
            generateSnackbarErrorMessage(error),
            "error"
          );
          throw error;
        } finally {
          setDataGridState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          setIsLoadingLinearProgress(false);
        }
      }
    } catch (error) {
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const initTransactionProofReportDataGrid = (
    data,
    rowCount,
    singleSelectData,
    templateData
  ) => {
    const gridColumns = [];
    const gridRows = [];
    const insertRow = {};

    // init column & insert row
    for (let i = 0; i < templateData.columns.length; i++) {
      let temp = {
        description:
          convertRulesToMUIDataType(templateData.rules[i]) === "date"
            ? "mm/dd/yyyy"
            : null,
        // if not id or formula then can edit
        editable: isDataGridCellEditable(templateData.rules[i]),
        field: templateData.fields[i],
        headerName: templateData.columns[i],
        minWidth: getDataGridColumnWidth(templateData.rules[i]),
        type: convertRulesToMUIDataType(templateData.rules[i]),
      };

      if (convertRulesToMUIDataType(templateData.rules[i]) === "singleSelect") {
        temp.valueOptions =
          singleSelectData[getSingleSelectFromRules(templateData.rules[i])];
      }

      gridColumns.push(temp);

      if (templateData.fields[i] === "transaction_proof_report_id") {
        insertRow[templateData.fields[i]] = 0;
      } else if (
        // avoid error (mui single select value cannot null)
        convertRulesToMUIDataType(templateData.rules[i]) === "singleSelect"
      ) {
        insertRow[templateData.fields[i]] = "";
      } else {
        insertRow[templateData.fields[i]] = null;
      }
    }

    gridRows.push(insertRow);

    // init row
    for (let i = 0; i < data.length; i++) {
      const keys = Object.keys(data[i]);

      for (let j = 0; j < keys.length; j++) {
        if (convertRulesToMUIDataType(templateData.rules[j]) === "date") {
          data[i][keys[j]] = parseISO(data[i][keys[j]]);

          if (!isValidDate(data[i][keys[j]])) {
            data[i][keys[j]] = "";
          }
        }
      }

      gridRows.push(data[i]);
    }

    setDataGridState((prevState) => ({
      ...prevState,
      columns: gridColumns,
      rowCount: rowCount,
      rows: gridRows,
    }));
  };

  useEffect(() => {
    fetchTransactionProofReportData(
      dataGridState.filterModel,
      dataGridState.pageIndex,
      dataGridState.rowsPerPage,
      dataGridState.sortModel
    );

    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={"flex-end"}
        spacing={2}
        sx={{ mb: 2 }}
      >
        <AuthElement
          moduleFeatures={{
            [modules.TRANSACTION_PROOF_REPORT.key]: {
              module: modules.TRANSACTION_PROOF_REPORT.key,
              features: [features.CREATE_DATA],
            },
          }}
        >
          <LoadingButton
            endIcon={<AddIcon />}
            loading={isLoadingButtonState.buttonCreate}
            loadingPosition="end"
            onClick={handleCreateTransactionProofReport}
          >
            Create
          </LoadingButton>
        </AuthElement>
        <AuthElement
          moduleFeatures={{
            [modules.TRANSACTION_PROOF_REPORT.key]: {
              module: modules.TRANSACTION_PROOF_REPORT.key,
              features: [features.DELETE_DATA],
            },
          }}
        >
          <LoadingButton
            disabled={dataGridState.rowSelectionModel.length <= 0}
            endIcon={<DeleteIcon />}
            loading={isLoadingButtonState.buttonDelete}
            loadingPosition="end"
            onClick={() => {
              dialogContext.handleOpenDialog(
                () => handleDeleteTransactionProofReport(),
                "Are you sure want to delete?",
                "Delete Confirmation"
              );
            }}
          >
            Delete
          </LoadingButton>
        </AuthElement>
        <AuthElement
          moduleFeatures={{
            [modules.TRANSACTION_PROOF_REPORT.key]: {
              module: modules.TRANSACTION_PROOF_REPORT.key,
              features: [
                features.EXPORT_DATA,
                features.EXPORT_TEMPLATE,
                features.IMPORT_DATA,
                features.IMPORT_TEMPLATE,
              ],
            },
          }}
        >
          <LoadingButton
            endIcon={<ExpandMoreIcon />}
            loading={
              isLoadingButtonState.buttonExportData ||
              isLoadingButtonState.buttonExportTemplate ||
              isLoadingButtonState.buttonImportData ||
              isLoadingButtonState.buttonImportTemplate
            }
            loadingPosition="end"
            onClick={handleOpenMenu}
          >
            More
          </LoadingButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            onClose={handleCloseMenu}
            open={open}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <AuthElement
              moduleFeatures={{
                [modules.TRANSACTION_PROOF_REPORT.key]: {
                  module: modules.TRANSACTION_PROOF_REPORT.key,
                  features: [features.IMPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  replaceTransactionProofReportDataRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faUpload} />
                </ListItemIcon>
                <ListItemText>
                  Replace Data
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="transaction-proof-report-data"
                    onChange={(e) => {
                      dialogContext.handleOpenDialog(
                        () => handleReplaceTransactionProofReportData(e),
                        "Your data will be lost, are you sure to continue?",
                        "Replace Data Confirmation"
                      );
                      handleCloseMenu();
                    }}
                    ref={replaceTransactionProofReportDataRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.TRANSACTION_PROOF_REPORT.key]: {
                  module: modules.TRANSACTION_PROOF_REPORT.key,
                  features: [features.IMPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  importTransactionProofReportDataRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileImport} />
                </ListItemIcon>
                <ListItemText>
                  Import Data
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="transaction-proof-report-data"
                    onChange={(e) => {
                      handleImportTransactionProofReportData(e);
                      handleCloseMenu();
                    }}
                    ref={importTransactionProofReportDataRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.TRANSACTION_PROOF_REPORT.key]: {
                  module: modules.TRANSACTION_PROOF_REPORT.key,
                  features: [features.EXPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleExportTransactionProofReportData(
                    dataGridState.columnVisibilityModel,
                    dataGridState.filterModel,
                    dataGridState.sortModel
                  );
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileExport} />
                </ListItemIcon>
                <ListItemText>Export Data</ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.TRANSACTION_PROOF_REPORT.key]: {
                  module: modules.TRANSACTION_PROOF_REPORT.key,
                  features: [features.IMPORT_TEMPLATE],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  importTransactionProofReportTemplateRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileArrowUp} />
                </ListItemIcon>
                <ListItemText>
                  Import Template
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="transaction-proof-report-template"
                    onChange={(e) => {
                      dialogContext.handleOpenDialog(
                        () => handleImportTransactionProofReportTemplate(e),
                        "Your data will be lost, are you sure to continue?",
                        "Import Template Confirmation"
                      );
                      handleCloseMenu();
                    }}
                    ref={importTransactionProofReportTemplateRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.TRANSACTION_PROOF_REPORT.key]: {
                  module: modules.TRANSACTION_PROOF_REPORT.key,
                  features: [features.EXPORT_TEMPLATE],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleExportTransactionProofReportTemplate();
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </ListItemIcon>
                <ListItemText>Export Template</ListItemText>
              </MenuItem>
            </AuthElement>
          </Menu>
        </AuthElement>
      </Stack>
      <AdminDataGrid
        dataGridState={dataGridState}
        fetchData={fetchTransactionProofReportData}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onRowSelectionModelChange={handleChangeRowSelectionModel}
        processRowUpdate={handleUpdateTransactionProofReport}
        rowId="transaction_proof_report_id"
      />
    </Paper>
  );
};

export default TransactionProofReport;
