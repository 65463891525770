export const manageWageColumn = [
  { field: "manage_wage_id", headerName: "ID", minWidth: 70 },
  {
    field: "manage_wage_type",
    headerName: "Tipe Gaji",
    minWidth: 300,
  },
  {
    editable: true,
    field: "manage_wage_status",
    headerName: "Status",
    minWidth: 150,
    type: "boolean",
  },
];
