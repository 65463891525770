import { Button, ThemeProvider } from "@mui/material";
import Lottie from "lottie-react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../../contexts/ThemeContextProvider";
import styles from "./Forbidden.module.scss";
import forbidden from "./forbidden.json";

const Forbidden = ({ isShowButton }) => {
  const navigate = useNavigate();
  const themeContext = useContext(ThemeContext);

  return (
    <ThemeProvider theme={themeContext.theme}>
      <div className={styles["forbidden__container"]}>
        <Lottie
          animationData={forbidden}
          className={styles["forbidden__animation"]}
          loop={true}
        />
        {isShowButton ? (
          <Button
            className={styles["forbidden__button"]}
            onClick={() => navigate("/")}
            variant="outlined"
          >
            Back
          </Button>
        ) : (
          <p className={styles["forbidden__text"]}>Forbidden</p>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Forbidden;
