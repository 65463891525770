import {
  faMoon,
  faRightFromBracket,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../../../contexts/ThemeContextProvider";
import { darkTheme } from "../../../themes/darkTheme";
import { lightTheme } from "../../../themes/lightTheme";
import cookie from "../../../utils/cookie";

const UserMenuItems = () => {
  const navigate = useNavigate();
  const themeContext = useContext(ThemeContext);

  const handleLogout = async () => {
    localStorage.clear();
    cookie.deleteAllCookies();
    navigate("/login");
  };

  const handleToggleTheme = async () => {
    if (themeContext.theme.palette.mode === "dark") {
      localStorage.setItem("theme", "light");
      themeContext.setTheme(lightTheme);
    } else if (themeContext.theme.palette.mode === "light") {
      localStorage.setItem("theme", "dark");
      themeContext.setTheme(darkTheme);
    }
  };

  return (
    <>
      {themeContext.theme.palette.mode === "dark" ? (
        <MenuItem onClick={handleToggleTheme}>
          <FontAwesomeIcon icon={faSun} /> &nbsp; Light Theme
        </MenuItem>
      ) : (
        <MenuItem onClick={handleToggleTheme}>
          <FontAwesomeIcon icon={faMoon} /> &nbsp; Dark Theme
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>
        <FontAwesomeIcon icon={faRightFromBracket} /> &nbsp; Logout
      </MenuItem>
    </>
  );
};

export default UserMenuItems;
