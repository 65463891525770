export const pph21CalculationProgressiveColumn = [
  { field: "pph21_calculation_progressive_id", headerName: "ID", minWidth: 70 },
  {
    field: "nip",
    headerName: "NIP",
    minWidth: 250,
  },
  {
    field: "status_db",
    headerName: "Status DB",
    minWidth: 250,
  },
  {
    field: "npwp",
    headerName: "NPWP",
    minWidth: 250,
  },
  {
    field: "ktp",
    headerName: "KTP",
    minWidth: 250,
  },
  {
    field: "nama_ktp",
    headerName: "Nama KTP",
    minWidth: 250,
  },
  {
    field: "departemen",
    headerName: "Departemen",
    minWidth: 250,
  },
  {
    field: "jabatan",
    headerName: "Jabatan",
    minWidth: 250,
  },
  {
    field: "status_pekerja",
    headerName: "Status Pekerja",
    minWidth: 250,
  },
  {
    field: "tanggal_mulai_kerja",
    headerName: "Tanggal Masuk Kerja",
    minWidth: 250,
    type: "date",
  },
  {
    field: "tanggal_berakhir_kerja",
    headerName: "Tanggal Berakhir Kerja",
    minWidth: 250,
    type: "date",
  },
  {
    field: "tanggal_pengangkatan_karyawan_tetap",
    headerName: "Tanggal Pengangkatan Karyawan Tetap",
    minWidth: 250,
    type: "date",
  },
  {
    field: "status_ptkp",
    headerName: "Status PTKP",
    minWidth: 250,
  },
  {
    field: "metode_perhitungan",
    headerName: "Metode Perhitungan",
    minWidth: 250,
  },
  {
    field: "masa_kerja",
    headerName: "Masa Kerja",
    minWidth: 250,
  },
  {
    field: "gaji_bruto_setahun",
    headerName: "Gaji Bruto Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "thr_setahun",
    headerName: "THR Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_intj_setahun",
    headerName: "Total INTJ Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_thr_intj_setahun",
    headerName: "Gaji Bruto + THR + INTJ Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "tunjangan_pph21_gross_up",
    headerName: "Tunjangan PPH21 Gross Up",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_tunjangan_pph21",
    headerName: "Gaji Bruto + Tunjangan PPH21",
    minWidth: 250,
    type: "number",
  },
  {
    field: "biaya_jabatan",
    headerName: "Biaya Jabatan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_setahun",
    headerName: "JHT Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_setahun",
    headerName: "JP Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_potongan_teratur_setahun",
    headerName: "Total Potongan Teratur Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "neto_setahun",
    headerName: "Neto Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "ptkp_setahun",
    headerName: "PTKP Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pkp_pembulatan_setahun",
    headerName: "PKP Pembulatan Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "tarif_pph21",
    headerName: "Tarif PPH21",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_setahun",
    headerName: "PPH21 Setahun",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_bulan_sebelumnya",
    headerName: "PPH21 Bulan Sebelumnya",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph_desember",
    headerName: "PPH Desember",
    minWidth: 250,
    type: "number",
  },
];
