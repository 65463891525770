import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import BusinessGroup from "./BusinessGroup";
import Department from "./Department";
import Grade from "./Grade";
import WorkingDay from "./WorkingDay";

const CompanyOrganization = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label={modules.BUSINESS_GROUP.value} value="0" />
            <Tab label={modules.DEPARTMENT.value} value="1" />
            <Tab label={modules.GRADE.value} value="2" />
            <Tab label={modules.WORKING_DAY.value} value="3" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.BUSINESS_GROUP.key]: {
                module: modules.BUSINESS_GROUP.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <BusinessGroup />
          </AuthElement>
        </TabPanel>
        <TabPanel value="1">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.DEPARTMENT.key]: {
                module: modules.DEPARTMENT.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <Department />
          </AuthElement>
        </TabPanel>
        <TabPanel value="2">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.GRADE.key]: {
                module: modules.GRADE.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <Grade />
          </AuthElement>
        </TabPanel>
        <TabPanel value="3">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.WORKING_DAY.key]: {
                module: modules.WORKING_DAY.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <WorkingDay />
          </AuthElement>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default CompanyOrganization;
