import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { Paper, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import AuthElement from "../../../components/AuthElement/AuthElement";
import AdminDataGridPro from "../../../components/admin/AdminDataGridPro";
import { taxSummaryColumn } from "../../../constants/columns/taxSummaryColumn";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { exportTaxSummaryData } from "../../../services/taxSummary/exportTaxSummaryData";
import { getTaxSummary } from "../../../services/taxSummary/getTaxSummary";
import { getTaxSummaryFilename } from "../../../services/taxSummary/getTaxSummaryFilename";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";
import isInteger from "../../../utils/isInteger";

const TaxSummary = () => {
  const [dataGridState, setDataGridState] = useState({
    columns: taxSummaryColumn,
    columnVisibilityModel: {},
    filterModel: {},
    isLoading: false,
    pinnedRows: { top: [], bottom: [] },
    rowId: "tax_summary_id",
    rowSelectionModel: [],
    rows: [],
    sortModel: [],
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonExportData: false,
    buttonRefreshData: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const snackbarContext = useContext(SnackbarContext);

  const fetchTaxSummaryData = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getTaxSummary();

      let tempQuery = {};

      if (res.payload.query.length > 0) {
        tempQuery = structuredClone(res.payload.query[0]);
        tempQuery.attendance_summary_id = 0;

        for (let i = 0; i < res.payload.query.length; i++) {
          const element = res.payload.query[i];
          const keys = Object.keys(element);
          const values = Object.values(element);

          for (let j = 0; j < keys.length; j++) {
            const tempKeys = keys[j];
            const tempValues = values[j];

            if (isInteger(tempValues)) {
              tempQuery[tempKeys] =
                parseInt(tempQuery[tempKeys]) + parseInt(tempValues);
            } else {
              tempQuery[tempKeys] = 0;
            }
          }
        }

        res.payload.query.push(tempQuery);
      }

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          pinnedRows: {
            top: [],
            bottom: res.payload.query.length > 0 ? [tempQuery] : [],
          },
          rows: res.payload.query,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportTaxSummaryData = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let filename = null;
      let res = await getTaxSummaryFilename();

      if (res.status === 200) {
        filename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportTaxSummaryData(
        {
          columnVisibilityModel: dataGridState.columnVisibilityModel,
          filterModel: dataGridState.filterModel,
          sortModel: dataGridState.sortModel,
        },
        { responseType: "arraybuffer" }
      );

      const link = document.createElement("a");
      const tempFilename = filename;
      link.setAttribute("download", tempFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Data Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    fetchTaxSummaryData();

    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={"flex-end"}
        spacing={2}
        sx={{ mb: 2 }}
      >
        <AuthElement
          moduleFeatures={{
            [modules.TAX_SUMMARY.key]: {
              module: modules.TAX_SUMMARY.key,
              features: [features.EXPORT_DATA],
            },
          }}
        >
          <LoadingButton
            endIcon={<FileDownloadIcon />}
            loading={isLoadingButtonState.buttonExportData}
            loadingPosition="end"
            onClick={() => {
              handleExportTaxSummaryData();
            }}
          >
            Export Data
          </LoadingButton>
        </AuthElement>
      </Stack>
      <AdminDataGridPro
        columns={dataGridState.columns}
        dataGridState={dataGridState}
        processRowUpdate={() => {}}
        setDataGridState={setDataGridState}
      />
    </Paper>
  );
};

export default TaxSummary;
