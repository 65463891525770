import { Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import AdminDataGridPro from "../../../components/admin/AdminDataGridPro";
import { manageWageColumn } from "../../../constants/columns/manageWageColumn";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { getWage } from "../../../services/manageWage/getWage";
import { updateWage } from "../../../services/manageWage/updateWage";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";

const ManageWage = () => {
  const [dataGridState, setDataGridState] = useState({
    columns: manageWageColumn,
    columnVisibilityModel: {},
    filterModel: {},
    isLoading: false,
    pinnedRows: { top: [], bottom: [] },
    rowId: "manage_wage_id",
    rowSelectionModel: [],
    rows: [],
    sortModel: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const snackbarContext = useContext(SnackbarContext);

  const fetchWage = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getWage();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        const tempWages = res.payload.query.map((wage) => {
          return {
            manage_wage_id: wage.manage_wage_id,
            manage_wage_type: wage.manage_wage_type,
            manage_wage_status: wage.manage_wage_status,
          };
        });

        setDataGridState((prevState) => ({
          ...prevState,
          rows: tempWages,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateWage = async (newRow) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await updateWage(newRow);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchWage();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    fetchWage();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <AdminDataGridPro
        columns={dataGridState.columns}
        dataGridState={dataGridState}
        processRowUpdate={handleUpdateWage}
        setDataGridState={setDataGridState}
      />
    </Paper>
  );
};

export default ManageWage;
