export const globalTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1360,
      lg: 1920,
      xl: 2560,
    },
  },
  components: {
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
};
