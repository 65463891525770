export const pph21CalculationTerColumn = [
  { field: "pph21_calculation_ter_id", headerName: "ID", minWidth: 70 },
  {
    field: "nip",
    headerName: "NIP",
    minWidth: 250,
  },
  {
    field: "status_db",
    headerName: "Status DB",
    minWidth: 250,
  },
  {
    field: "npwp",
    headerName: "NPWP",
    minWidth: 250,
  },
  {
    field: "ktp",
    headerName: "KTP",
    minWidth: 250,
  },
  {
    field: "nama_ktp",
    headerName: "Nama KTP",
    minWidth: 250,
  },
  {
    field: "departemen",
    headerName: "Departemen",
    minWidth: 250,
  },
  {
    field: "status_pekerja",
    headerName: "Status Pekerja",
    minWidth: 250,
  },
  {
    field: "tanggal_mulai_kerja",
    headerName: "Tanggal Masuk Kerja",
    minWidth: 250,
    type: "date",
  },
  {
    field: "tanggal_berakhir_kerja",
    headerName: "Tanggal Berakhir Kerja",
    minWidth: 250,
    type: "date",
  },
  {
    field: "status_ptkp",
    headerName: "Status PTKP",
    minWidth: 250,
  },
  {
    field: "metode_perhitungan",
    headerName: "Metode Perhitungan",
    minWidth: 250,
  },
  {
    field: "kategori_ter",
    headerName: "Kategori TER",
    minWidth: 250,
  },
  {
    field: "gaji_bruto",
    headerName: "Gaji Bruto",
    minWidth: 250,
    type: "number",
  },
  {
    field: "tunjangan_pph21",
    headerName: "Tunjangan PPH21",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_tunjangan_pph21",
    headerName: "Gaji Bruto + Tunjangan PPH21",
    minWidth: 250,
    type: "number",
  },
  {
    field: "tarif",
    headerName: "Tarif",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21",
    headerName: "PPH21",
    minWidth: 250,
    type: "number",
  },
];
