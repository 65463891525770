import Lottie from "lottie-react";
import React from "react";
import empty from "./empty.json";
import styles from "./Empty.module.scss";

const Empty = () => {
  return (
    <div className={styles["empty__container"]}>
      <Lottie animationData={empty} loop={true} />
      <p className={styles["empty__text"]}>No Data</p>
    </div>
  );
};

export default Empty;
