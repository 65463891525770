import { yupResolver } from "@hookform/resolvers/yup";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ThemeSwitch from "../../components/ThemeSwitch/ThemeSwitch";
import SnackbarContext from "../../contexts/SnackbarContextProvider";
import ThemeContext from "../../contexts/ThemeContextProvider";
import { loginSchema } from "../../schemas/loginSchema";
import { login } from "../../services/authentication/login";
import cookie from "../../utils/cookie";
import { generateSnackbarErrorMessage } from "../../utils/generateSnackbarErrorMessage";

const Login = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      company_code: "",
      password: "",
      username: "",
    },
    resolver: yupResolver(loginSchema),
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);
  const themeContext = useContext(ThemeContext);

  const onSubmitLogin = async (data) => {
    try {
      setIsLoadingButtonSubmit(true);

      data.company_code = data.company_code.toLowerCase();

      const res = await login(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        cookie.setCookie(
          "is_logged_in",
          true,
          `${process.env.REACT_APP_COOKIE_EXPIRES_IN}`
        );
        localStorage.setItem("company_code", res.payload.company_code);
        localStorage.setItem("user_id", res.payload.id);
        localStorage.setItem("user_username", res.payload.username);
        localStorage.setItem(
          "user_profile_picture_name",
          res.payload.profile_picture_name
        );
        localStorage.setItem("user_role_id", res.payload.role_id);
        localStorage.setItem("user_role_name", res.payload.role_name);
        localStorage.setItem("user_role_json", res.payload.role_json);
        navigate("/admin");
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonSubmit(false);
    }
  };

  return (
    <ThemeProvider theme={themeContext.theme}>
      <Paper elevation={0} sx={{ borderRadius: 0 }}>
        <Container
          maxWidth="sm"
          sx={{
            alignItems: "center",
            display: "flex",
            minHeight: "100vh",
            px: 2,
            py: 4,
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <form onSubmit={handleSubmit(onSubmitLogin)}>
              <Paper sx={{ p: 3 }}>
                <Stack alignItems="flex-end">
                  <ThemeSwitch />
                </Stack>
                <Stack>
                  <Typography
                    fontWeight="bold"
                    sx={{
                      fontSize: "clamp(1.5rem, 5vw, 2.5rem)",
                      mb: 5,
                      mt: 4,
                      textAlign: "center",
                    }}
                    variant="h3"
                  >
                    Codeflair
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="company_code"
                        render={({
                          field: { onChange, ref, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            error={!!error}
                            fullWidth
                            helperText={error?.message}
                            inputRef={ref}
                            label="Company Code"
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="username"
                        render={({
                          field: { onChange, ref, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            error={!!error}
                            fullWidth
                            helperText={error?.message}
                            inputRef={ref}
                            label="Username"
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="password"
                        render={({
                          field: { onChange, ref, value },
                          fieldState: { error },
                        }) => (
                          <FormControl fullWidth>
                            <InputLabel error={!!error}>Password</InputLabel>
                            <OutlinedInput
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setIsShowPassword(!isShowPassword)
                                    }
                                    edge="end"
                                  >
                                    {isShowPassword ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              error={!!error}
                              label="Password"
                              onChange={onChange}
                              ref={ref}
                              type={isShowPassword ? "text" : "password"}
                              value={value}
                            />
                            <FormHelperText error>
                              {error?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        direction={{ sm: "row", xs: "column" }}
                        justifyContent="flex-end"
                      >
                        <LoadingButton
                          endIcon={<LoginIcon />}
                          loading={isLoadingButtonSubmit}
                          loadingPosition="end"
                          type="submit"
                        >
                          Login
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Paper>
            </form>
          </Stack>
        </Container>
      </Paper>
    </ThemeProvider>
  );
};

export default Login;
