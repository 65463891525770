export const salaryCalculationColumn = [
  { field: "salary_calculation_id", headerName: "ID", minWidth: 70 },
  {
    field: "nip",
    headerName: "NIP",
    minWidth: 250,
  },
  {
    field: "nama",
    headerName: "Nama",
    minWidth: 250,
  },
  {
    field: "departemen",
    headerName: "Departemen",
    minWidth: 250,
  },
  {
    field: "jabatan",
    headerName: "Jabatan",
    minWidth: 250,
  },
  {
    field: "grade",
    headerName: "Grade",
    minWidth: 250,
  },
  {
    field: "status_pekerja",
    headerName: "Status Pekerja",
    minWidth: 250,
  },
  {
    field: "upah",
    headerName: "Upah",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_tunjangan_tidak_tetap",
    headerName: "Total Tunjangan Tidak Tetap",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_tk_jkk_perusahaan",
    headerName: "BPJS TK-JKK Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_tk_jkm_perusahaan",
    headerName: "BPJS TK-JKM Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_tk_jht_perusahaan",
    headerName: "BPJS TK-JHT Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_tk_jht_pegawai",
    headerName: "BPJS TK-JHT Pegawai",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_tk_jp_perusahaan",
    headerName: "BPJS TK-JP Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_tk_jp_pegawai",
    headerName: "BPJS TK-JP Pegawai",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_kes_perusahaan",
    headerName: "BPJS KES Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_kes_pegawai",
    headerName: "BPJS KES Pegawai",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_potongan_bpjs_perusahaan",
    headerName: "Total Potongan BPJS Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_potongan_bpjs_pegawai",
    headerName: "Total Potongan BPJS Pegawai",
    minWidth: 250,
    type: "number",
  },
  {
    field: "potongan_absensi",
    headerName: "Potongan Absensi",
    minWidth: 250,
    type: "number",
  },
  {
    field: "potongan_terlambat",
    headerName: "Potongan Terlambat",
    minWidth: 250,
    type: "number",
  },
  {
    field: "potongan_pulang_cepat",
    headerName: "Potongan Pulang Cepat",
    minWidth: 250,
    type: "number",
  },
  {
    field: "lembur",
    headerName: "Lembur",
    minWidth: 250,
    type: "number",
  },
  {
    field: "koreksi_bulan_lalu",
    headerName: "Koreksi Bulan Lalu",
    minWidth: 250,
    type: "number",
  },
  {
    field: "koreksi_bulan_ini",
    headerName: "Koreksi Bulan Ini",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_sebelum_potongan_bpjs",
    headerName: "Gaji Sebelum Potongan BPJS",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto",
    headerName: "Gaji Bruto",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_sebelum_pajak",
    headerName: "Gaji Sebelum Pajak",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21",
    headerName: "PPH21",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_setelah_pajak",
    headerName: "Gaji Setelah Pajak",
    minWidth: 250,
    type: "number",
  },
  {
    field: "hutang",
    headerName: "Hutang",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_ditransfer",
    headerName: "Gaji Ditransfer",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_tahap_1",
    headerName: "Gaji Tahap 1",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_tahap_2",
    headerName: "Gaji Tahap 2",
    minWidth: 250,
    type: "number",
  },
];
