export const generateBreadcrumbs = (
  drawerItems,
  detailIcon,
  CustomPropsBreadcrumb
) => {
  const tempBreadcrumbs = [];

  for (let i = 0; i < drawerItems.length; i++) {
    const drawerItem = drawerItems[i];

    if (drawerItem.children) {
      for (let j = 0; j < drawerItem.children.length; j++) {
        const drawerChild = drawerItem.children[j];

        tempBreadcrumbs.push({
          breadcrumb: CustomPropsBreadcrumb,
          path: drawerChild.path,
          props: { icon: drawerChild.icon, text: drawerChild.text },
        });

        tempBreadcrumbs.push({
          breadcrumb: CustomPropsBreadcrumb,
          path: drawerChild.path + "/:id",
          props: { icon: detailIcon, text: "Detail " + drawerChild.text },
        });
      }
    } else {
      tempBreadcrumbs.push({
        breadcrumb: CustomPropsBreadcrumb,
        path: drawerItem.path,
        props: { icon: drawerItem.icon, text: drawerItem.text },
      });

      tempBreadcrumbs.push({
        breadcrumb: CustomPropsBreadcrumb,
        path: drawerItem.path + "/:id",
        props: { icon: detailIcon, text: "Detail " + drawerItem.text },
      });
    }
  }

  return tempBreadcrumbs;
};
