import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LicenseInfo } from "@mui/x-license";
import React, { useContext } from "react";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter } from "react-router-dom";
import "./ReactQuill.scss";
import { DialogContextProvider } from "./contexts/DialogContextProvider";
import { LoaderContextProvider } from "./contexts/LoaderContextProvider";
import { SnackbarContextProvider } from "./contexts/SnackbarContextProvider";
import ThemeContext from "./contexts/ThemeContextProvider";
import "./index.scss";
import MyRoutes from "./routes/MyRoutes";

function App() {
  LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE_KEY}`);

  const themeContext = useContext(ThemeContext);

  return (
    <ThemeProvider theme={themeContext.theme}>
      <BrowserRouter>
        <DialogContextProvider>
          <LoaderContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarContextProvider>
                <MyRoutes />
              </SnackbarContextProvider>
            </LocalizationProvider>
          </LoaderContextProvider>
        </DialogContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
