import * as yup from "yup";

export const manageCompanySchema = yup.object().shape({
  companyEmail: yup
    .string()
    .email("Wrong Company Email format.")
    .required("Please enter your Company Email."),
  companyName: yup
    .string()
    .trim()
    .max(32, "The length of your Company Name must be 32 characters or fewer.")
    .min(2, "The length of your Company Name must be at least 2 characters."),
  username: yup
    .string()
    .max(32, "The length of your Username must be 32 characters or fewer.")
    .required("Please enter your Username."),
  password: yup
    .string()
    .max(12, "The length of your Password must be 12 characters or fewer.")
    .min(6, "The length of your Password must be at least 6 characters."),
  confirmPassword: yup
    .string()
    .max(
      12,
      "The length of your Confirm Password must be 12 characters or fewer."
    )
    .min(
      6,
      "The length of your Confirm Password must be at least 6 characters."
    )
    .oneOf(
      [yup.ref("password")],
      "Password and Confirm Password does not match."
    ),
});
