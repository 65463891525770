import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import Bank from "./Bank";
import BpjsKesehatan from "./BpjsKesehatan";
import BpjsKetenagakerjaan from "./BpjsKetenagakerjaan";
import FamilyCard from "./FamilyCard";
import IdentityCard from "./IdentityCard";
import TaxData from "./TaxData";

const EmployeePersonalData = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label={modules.IDENTITY_CARD.value} value="0" />
            <Tab label={modules.FAMILY_CARD.value} value="1" />
            <Tab label={modules.BANK.value} value="2" />
            <Tab label={modules.BPJS_KESEHATAN.value} value="3" />
            <Tab label={modules.BPJS_KETENAGAKERJAAN.value} value="4" />
            <Tab label={modules.TAX_DATA.value} value="5" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.IDENTITY_CARD.key]: {
                module: modules.IDENTITY_CARD.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <IdentityCard />
          </AuthElement>
        </TabPanel>
        <TabPanel value="1">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.FAMILY_CARD.key]: {
                module: modules.FAMILY_CARD.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <FamilyCard />
          </AuthElement>
        </TabPanel>
        <TabPanel value="2">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.BANK.key]: {
                module: modules.BANK.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <Bank />
          </AuthElement>
        </TabPanel>
        <TabPanel value="3">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.BPJS_KESEHATAN.key]: {
                module: modules.BPJS_KESEHATAN.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <BpjsKesehatan />
          </AuthElement>
        </TabPanel>
        <TabPanel value="4">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.BPJS_KETENAGAKERJAAN.key]: {
                module: modules.BPJS_KETENAGAKERJAAN.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <BpjsKetenagakerjaan />
          </AuthElement>
        </TabPanel>
        <TabPanel value="5">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.TAX_DATA.key]: {
                module: modules.TAX_DATA.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <TaxData />
          </AuthElement>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default EmployeePersonalData;
