import {
  faBuilding,
  faBuildingLock,
  faBuildingUser,
  faBusinessTime,
  faCalculator,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCalendarDays,
  faCalendarMinus,
  faCalendarWeek,
  faChartLine,
  faCoins,
  faCommentsDollar,
  faEarthAsia,
  faFileInvoiceDollar,
  faFileLines,
  faFileSignature,
  faFileWaveform,
  faFilterCircleDollar,
  faGears,
  faHandHoldingDollar,
  faHospital,
  faHospitalUser,
  faHourglass,
  faIdCard,
  faPiggyBank,
  faSackDollar,
  faScaleUnbalanced,
  faSkull,
  faUserGear,
  faUserGroup,
  faUserPen,
  faUserTie,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { features } from "./features";
import { modules } from "./modules";

export const adminDrawerItems = [
  {
    icon: <FontAwesomeIcon icon={faChartLine} />,
    moduleFeatures: {
      [modules.DASHBOARD.key]: {
        module: modules.DASHBOARD.key,
        features: [features.READ_DATA],
      },
    },
    path: `/admin/${modules.DASHBOARD.key.toLowerCase()}`,
    text: modules.DASHBOARD.value,
  },
  {
    icon: <FontAwesomeIcon icon={faEarthAsia} />,
    isOpen: true,
    moduleFeatures: {
      [modules.COUNTRY_BPJS.key]: {
        module: modules.COUNTRY_BPJS.key,
        features: [features.READ_DATA],
      },
      [modules.COUNTRY_MINIMUM_WAGE.key]: {
        module: modules.COUNTRY_MINIMUM_WAGE.key,
        features: [features.READ_DATA],
      },
      [modules.JKK_CONTRIBUTION.key]: {
        module: modules.JKK_CONTRIBUTION.key,
        features: [features.READ_DATA],
      },
      [modules.PPH21_PROGRESSIVE.key]: {
        module: modules.PPH21_PROGRESSIVE.key,
        features: [features.READ_DATA],
      },
      [modules.PPH21_TER.key]: {
        module: modules.PPH21_TER.key,
        features: [features.READ_DATA],
      },
      [modules.PTKP_TER.key]: {
        module: modules.PTKP_TER.key,
        features: [features.READ_DATA],
      },
      [modules.PTKP_PROGRESSIVE.key]: {
        module: modules.PTKP_PROGRESSIVE.key,
        features: [features.READ_DATA],
      },
    },
    text: "Master Data Negara",
    children: [
      {
        icon: <FontAwesomeIcon icon={faPiggyBank} />,
        moduleFeatures: {
          [modules.COUNTRY_MINIMUM_WAGE.key]: {
            module: modules.COUNTRY_MINIMUM_WAGE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.COUNTRY_MINIMUM_WAGE.key.toLowerCase()}`,
        text: modules.COUNTRY_MINIMUM_WAGE.value,
      },
      {
        icon: <FontAwesomeIcon icon={faHospital} />,
        moduleFeatures: {
          [modules.COUNTRY_BPJS.key]: {
            module: modules.COUNTRY_BPJS.key,
            features: [features.READ_DATA],
          },
          [modules.JKK_CONTRIBUTION.key]: {
            module: modules.JKK_CONTRIBUTION.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/country_bpjs`,
        text: "BPJS",
      },
      {
        icon: <FontAwesomeIcon icon={faCoins} />,
        moduleFeatures: {
          [modules.PTKP_TER.key]: {
            module: modules.PTKP_TER.key,
            features: [features.READ_DATA],
          },
          [modules.PTKP_PROGRESSIVE.key]: {
            module: modules.PTKP_PROGRESSIVE.key,
            features: [features.READ_DATA],
          },
          [modules.PPH21_PROGRESSIVE.key]: {
            module: modules.PPH21_PROGRESSIVE.key,
            features: [features.READ_DATA],
          },
          [modules.PPH21_TER.key]: {
            module: modules.PPH21_TER.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/country_tax`,
        text: "Pajak",
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faBuilding} />,
    isOpen: true,
    moduleFeatures: {
      [modules.BUSINESS_GROUP.key]: {
        module: modules.BUSINESS_GROUP.key,
        features: [features.READ_DATA],
      },
      [modules.DEPARTMENT.key]: {
        module: modules.DEPARTMENT.key,
        features: [features.READ_DATA],
      },
      [modules.GRADE.key]: {
        module: modules.GRADE.key,
        features: [features.READ_DATA],
      },
      [modules.HOLIDAY.key]: {
        module: modules.HOLIDAY.key,
        features: [features.READ_DATA],
      },
      [modules.PERIOD.key]: {
        module: modules.PERIOD.key,
        features: [features.READ_DATA],
      },
      [modules.WAGE_STRUCTURE_GRADE.key]: {
        module: modules.WAGE_STRUCTURE_GRADE.key,
        features: [features.READ_DATA],
      },
      [modules.WAGE_STRUCTURE_YEARS_OF_SERVICE.key]: {
        module: modules.WAGE_STRUCTURE_YEARS_OF_SERVICE.key,
        features: [features.READ_DATA],
      },
      [modules.WORKING_DAY.key]: {
        module: modules.WORKING_DAY.key,
        features: [features.READ_DATA],
      },
      [modules.WORK_SCHEDULE.key]: {
        module: modules.WORK_SCHEDULE.key,
        features: [features.READ_DATA],
      },
    },
    text: "Master Data Perusahaan",
    children: [
      {
        icon: <FontAwesomeIcon icon={faUserGroup} />,
        moduleFeatures: {
          [modules.BUSINESS_GROUP.key]: {
            module: modules.BUSINESS_GROUP.key,
            features: [features.READ_DATA],
          },
          [modules.DEPARTMENT.key]: {
            module: modules.DEPARTMENT.key,
            features: [features.READ_DATA],
          },
          [modules.GRADE.key]: {
            module: modules.GRADE.key,
            features: [features.READ_DATA],
          },
          [modules.WORKING_DAY.key]: {
            module: modules.WORKING_DAY.key,
            features: [features.READ_DATA],
          },
        },
        path: "/admin/company_organization",
        text: "Struktur Organisasi",
      },
      {
        icon: <FontAwesomeIcon icon={faHandHoldingDollar} />,
        moduleFeatures: {
          [modules.WAGE_STRUCTURE_GRADE.key]: {
            module: modules.WAGE_STRUCTURE_GRADE.key,
            features: [features.READ_DATA],
          },
          [modules.WAGE_STRUCTURE_YEARS_OF_SERVICE.key]: {
            module: modules.WAGE_STRUCTURE_YEARS_OF_SERVICE.key,
            features: [features.READ_DATA],
          },
        },
        path: "/admin/company_wage",
        text: "Struktur Upah",
      },
      {
        icon: <FontAwesomeIcon icon={faCalendarMinus} />,
        moduleFeatures: {
          [modules.HOLIDAY.key]: {
            module: modules.HOLIDAY.key,
            features: [features.READ_DATA],
          },
          [modules.PERIOD.key]: {
            module: modules.PERIOD.key,
            features: [features.READ_DATA],
          },
          [modules.WORK_SCHEDULE.key]: {
            module: modules.WORK_SCHEDULE.key,
            features: [features.READ_DATA],
          },
        },
        path: "/admin/company_attendance",
        text: "Kalender",
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faUserTie} />,
    isOpen: true,
    moduleFeatures: {
      [modules.BANK.key]: {
        module: modules.BANK.key,
        features: [features.READ_DATA],
      },
      [modules.BPJS_KESEHATAN.key]: {
        module: modules.BPJS_KESEHATAN.key,
        features: [features.READ_DATA],
      },
      [modules.BPJS_KETENAGAKERJAAN.key]: {
        module: modules.BPJS_KETENAGAKERJAAN.key,
        features: [features.READ_DATA],
      },
      [modules.BPJS_MEMBERSHIP.key]: {
        module: modules.BPJS_MEMBERSHIP.key,
        features: [features.READ_DATA],
      },
      [modules.DEBT.key]: {
        module: modules.DEBT.key,
        features: [features.READ_DATA],
      },
      [modules.FAMILY_CARD.key]: {
        module: modules.FAMILY_CARD.key,
        features: [features.READ_DATA],
      },
      [modules.IDENTITY_CARD.key]: {
        module: modules.IDENTITY_CARD.key,
        features: [features.READ_DATA],
      },
      [modules.TAX_DATA.key]: {
        module: modules.TAX_DATA.key,
        features: [features.READ_DATA],
      },
      [modules.WAGE_AGREEMENT.key]: {
        module: modules.WAGE_AGREEMENT.key,
        features: [features.READ_DATA],
      },
      [modules.WORK_AGREEMENT.key]: {
        module: modules.WORK_AGREEMENT.key,
        features: [features.READ_DATA],
      },
    },
    text: "Master Data Pegawai",
    children: [
      {
        icon: <FontAwesomeIcon icon={faIdCard} />,
        moduleFeatures: {
          [modules.BANK.key]: {
            module: modules.BANK.key,
            features: [features.READ_DATA],
          },
          [modules.BPJS_KESEHATAN.key]: {
            module: modules.BPJS_KESEHATAN.key,
            features: [features.READ_DATA],
          },
          [modules.BPJS_KETENAGAKERJAAN.key]: {
            module: modules.BPJS_KETENAGAKERJAAN.key,
            features: [features.READ_DATA],
          },
          [modules.FAMILY_CARD.key]: {
            module: modules.FAMILY_CARD.key,
            features: [features.READ_DATA],
          },
          [modules.IDENTITY_CARD.key]: {
            module: modules.IDENTITY_CARD.key,
            features: [features.READ_DATA],
          },
          [modules.TAX_DATA.key]: {
            module: modules.TAX_DATA.key,
            features: [features.READ_DATA],
          },
        },
        path: "/admin/employee_personal_data",
        text: "Data Pribadi",
      },
      {
        icon: <FontAwesomeIcon icon={faBuildingUser} />,
        moduleFeatures: {
          [modules.WAGE_AGREEMENT.key]: {
            module: modules.WAGE_AGREEMENT.key,
            features: [features.READ_DATA],
          },
          [modules.WORK_AGREEMENT.key]: {
            module: modules.WORK_AGREEMENT.key,
            features: [features.READ_DATA],
          },
        },
        path: "/admin/employee_employment",
        text: "Data Kepegawaian",
      },
      {
        icon: <FontAwesomeIcon icon={faHospitalUser} />,
        moduleFeatures: {
          [modules.BPJS_MEMBERSHIP.key]: {
            module: modules.BPJS_MEMBERSHIP.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.BPJS_MEMBERSHIP.key.toLowerCase()}`,
        text: modules.BPJS_MEMBERSHIP.value,
      },
      {
        icon: <FontAwesomeIcon icon={faCommentsDollar} />,
        moduleFeatures: {
          [modules.DEBT.key]: {
            module: modules.DEBT.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.DEBT.key.toLowerCase()}`,
        text: modules.DEBT.value,
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faCalendar} />,
    isOpen: true,
    moduleFeatures: {
      [modules.ATTENDANCE_SUMMARY.key]: {
        module: modules.ATTENDANCE_SUMMARY.key,
        features: [features.READ_DATA],
      },
      [modules.MANUAL_ATTENDANCE_WEEK_1.key]: {
        module: modules.MANUAL_ATTENDANCE_WEEK_1.key,
        features: [features.READ_DATA],
      },
      [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
        module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
        features: [features.READ_DATA],
      },
      [modules.MANUAL_ATTENDANCE_WEEK_3.key]: {
        module: modules.MANUAL_ATTENDANCE_WEEK_3.key,
        features: [features.READ_DATA],
      },
      [modules.MANUAL_ATTENDANCE_WEEK_4.key]: {
        module: modules.MANUAL_ATTENDANCE_WEEK_4.key,
        features: [features.READ_DATA],
      },
      [modules.OVERTIME.key]: {
        module: modules.OVERTIME.key,
        features: [features.READ_DATA],
      },
    },
    text: "Absensi",
    children: [
      {
        icon: <FontAwesomeIcon icon={faCalendarWeek} />,
        moduleFeatures: {
          [modules.MANUAL_ATTENDANCE_WEEK_1.key]: {
            module: modules.MANUAL_ATTENDANCE_WEEK_1.key,
            features: [features.READ_DATA],
          },
          [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
            module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
            features: [features.READ_DATA],
          },
          [modules.MANUAL_ATTENDANCE_WEEK_3.key]: {
            module: modules.MANUAL_ATTENDANCE_WEEK_3.key,
            features: [features.READ_DATA],
          },
          [modules.MANUAL_ATTENDANCE_WEEK_4.key]: {
            module: modules.MANUAL_ATTENDANCE_WEEK_4.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/manual_attendance`,
        text: "Input Absensi",
      },
      {
        icon: <FontAwesomeIcon icon={faBusinessTime} />,
        moduleFeatures: {
          [modules.OVERTIME.key]: {
            module: modules.OVERTIME.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.OVERTIME.key.toLowerCase()}`,
        text: "Lembur",
      },
      {
        icon: <FontAwesomeIcon icon={faCalendarDays} />,
        moduleFeatures: {
          [modules.ATTENDANCE_SUMMARY.key]: {
            module: modules.ATTENDANCE_SUMMARY.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.ATTENDANCE_SUMMARY.key.toLowerCase()}`,
        text: "Rekap",
      },
      {
        icon: <FontAwesomeIcon icon={faCalendarCheck} />,
        moduleFeatures: {
          [modules.ATTENDANCE_CORRECTION.key]: {
            module: modules.ATTENDANCE_CORRECTION.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.ATTENDANCE_CORRECTION.key.toLowerCase()}`,
        text: modules.ATTENDANCE_CORRECTION.value,
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faSackDollar} />,
    isOpen: true,
    moduleFeatures: {
      [modules.TAX_SUMMARY.key]: {
        module: modules.TAX_SUMMARY.key,
        features: [features.READ_DATA],
      },
    },
    text: "Pajak",
    children: [
      {
        icon: <FontAwesomeIcon icon={faFileLines} />,
        moduleFeatures: {
          [modules.TAX_SUMMARY.key]: {
            module: modules.TAX_SUMMARY.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.TAX_SUMMARY.key.toLowerCase()}`,
        text: "Rekap",
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faCalculator} />,
    isOpen: true,
    moduleFeatures: {
      [modules.ATTENDANCE_CALCULATION.key]: {
        module: modules.ATTENDANCE_CALCULATION.key,
        features: [features.READ_DATA],
      },
      [modules.BPJS_CALCULATION.key]: {
        module: modules.BPJS_CALCULATION.key,
        features: [features.READ_DATA],
      },
      [modules.PPH21_CALCULATION_PROGRESSIVE.key]: {
        module: modules.PPH21_CALCULATION_PROGRESSIVE.key,
        features: [features.READ_DATA],
      },
      [modules.PPH21_CALCULATION_TER.key]: {
        module: modules.PPH21_CALCULATION_TER.key,
        features: [features.READ_DATA],
      },
      [modules.SALARY_CALCULATION.key]: {
        module: modules.SALARY_CALCULATION.key,
        features: [features.READ_DATA],
      },
    },
    text: "Perhitungan",
    children: [
      {
        icon: <FontAwesomeIcon icon={faFileWaveform} />,
        moduleFeatures: {
          [modules.BPJS_CALCULATION.key]: {
            module: modules.BPJS_CALCULATION.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.BPJS_CALCULATION.key.toLowerCase()}`,
        text: modules.BPJS_CALCULATION.value,
      },
      {
        icon: <FontAwesomeIcon icon={faCalendarDay} />,
        moduleFeatures: {
          [modules.ATTENDANCE_CALCULATION.key]: {
            module: modules.ATTENDANCE_CALCULATION.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.ATTENDANCE_CALCULATION.key.toLowerCase()}`,
        text: "Absensi",
      },
      {
        icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
        moduleFeatures: {
          [modules.SALARY_CALCULATION.key]: {
            module: modules.SALARY_CALCULATION.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.SALARY_CALCULATION.key.toLowerCase()}`,
        text: "Gaji",
      },
      {
        icon: <FontAwesomeIcon icon={faFileSignature} />,
        moduleFeatures: {
          [modules.PPH21_CALCULATION_TER.key]: {
            module: modules.PPH21_CALCULATION_TER.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.PPH21_CALCULATION_TER.key.toLowerCase()}`,
        text: "Pajak Ter",
      },
      {
        icon: <FontAwesomeIcon icon={faFileSignature} />,
        moduleFeatures: {
          [modules.PPH21_CALCULATION_PROGRESSIVE.key]: {
            module: modules.PPH21_CALCULATION_PROGRESSIVE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.PPH21_CALCULATION_PROGRESSIVE.key.toLowerCase()}`,
        text: "Pajak Progresif",
      },
    ],
  },
  // {
  //   icon: <FontAwesomeIcon icon={faScroll} />,
  //   isOpen: true,
  //   moduleFeatures: {
  //     [modules.ATTENDANCE_REPORT.key]: {
  //       module: modules.ATTENDANCE_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.BPJS_KESEHATAN_REPORT.key]: {
  //       module: modules.BPJS_KESEHATAN_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.BPJS_KETENAGAKERJAAN_REPORT.key]: {
  //       module: modules.BPJS_KETENAGAKERJAAN_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.DEBT_REPORT.key]: {
  //       module: modules.DEBT_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.DEPARTMENT_WAGE_REPORT.key]: {
  //       module: modules.DEPARTMENT_WAGE_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.EMPLOYEE_WAGE_REPORT.key]: {
  //       module: modules.EMPLOYEE_WAGE_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.PAYMENT_ORDER_REPORT.key]: {
  //       module: modules.PAYMENT_ORDER_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.PPH21_REPORT.key]: {
  //       module: modules.PPH21_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.SALARY_SLIP_REPORT.key]: {
  //       module: modules.SALARY_SLIP_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.TRANSACTION_PROOF_REPORT.key]: {
  //       module: modules.TRANSACTION_PROOF_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //     [modules.WAGE_ON_HOLD_REPORT.key]: {
  //       module: modules.WAGE_ON_HOLD_REPORT.key,
  //       features: [features.READ_DATA],
  //     },
  //   },
  //   text: "Laporan",
  //   children: [
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.BPJS_KESEHATAN_REPORT.key]: {
  //           module: modules.BPJS_KESEHATAN_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.BPJS_KESEHATAN_REPORT.key.toLowerCase()}`,
  //       text: modules.BPJS_KESEHATAN_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.BPJS_KETENAGAKERJAAN_REPORT.key]: {
  //           module: modules.BPJS_KETENAGAKERJAAN_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.BPJS_KETENAGAKERJAAN_REPORT.key.toLowerCase()}`,
  //       text: modules.BPJS_KETENAGAKERJAAN_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.ATTENDANCE_REPORT.key]: {
  //           module: modules.ATTENDANCE_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.ATTENDANCE_REPORT.key.toLowerCase()}`,
  //       text: modules.ATTENDANCE_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.WAGE_ON_HOLD_REPORT.key]: {
  //           module: modules.WAGE_ON_HOLD_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.WAGE_ON_HOLD_REPORT.key.toLowerCase()}`,
  //       text: modules.WAGE_ON_HOLD_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.DEBT_REPORT.key]: {
  //           module: modules.DEBT_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.DEBT_REPORT.key.toLowerCase()}`,
  //       text: modules.DEBT_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.PPH21_REPORT.key]: {
  //           module: modules.PPH21_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.PPH21_REPORT.key.toLowerCase()}`,
  //       text: modules.PPH21_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.EMPLOYEE_WAGE_REPORT.key]: {
  //           module: modules.EMPLOYEE_WAGE_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.EMPLOYEE_WAGE_REPORT.key.toLowerCase()}`,
  //       text: modules.EMPLOYEE_WAGE_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.DEPARTMENT_WAGE_REPORT.key]: {
  //           module: modules.DEPARTMENT_WAGE_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.DEPARTMENT_WAGE_REPORT.key.toLowerCase()}`,
  //       text: modules.DEPARTMENT_WAGE_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.PAYMENT_ORDER_REPORT.key]: {
  //           module: modules.PAYMENT_ORDER_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.PAYMENT_ORDER_REPORT.key.toLowerCase()}`,
  //       text: modules.PAYMENT_ORDER_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.TRANSACTION_PROOF_REPORT.key]: {
  //           module: modules.TRANSACTION_PROOF_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.TRANSACTION_PROOF_REPORT.key.toLowerCase()}`,
  //       text: modules.TRANSACTION_PROOF_REPORT.value,
  //     },
  //     {
  //       icon: <FontAwesomeIcon icon={faScroll} />,
  //       moduleFeatures: {
  //         [modules.SALARY_SLIP_REPORT.key]: {
  //           module: modules.SALARY_SLIP_REPORT.key,
  //           features: [features.READ_DATA],
  //         },
  //       },
  //       path: `/admin/${modules.SALARY_SLIP_REPORT.key.toLowerCase()}`,
  //       text: modules.SALARY_SLIP_REPORT.value,
  //     },
  //   ],
  // },
  {
    icon: <FontAwesomeIcon icon={faGears} />,
    isOpen: true,
    moduleFeatures: {
      [modules.MANAGE_COMPANY.key]: {
        module: modules.MANAGE_COMPANY.key,
        features: [features.READ_DATA],
      },
      [modules.MANAGE_JKK_CONTRIBUTION.key]: {
        module: modules.MANAGE_JKK_CONTRIBUTION.key,
        features: [features.READ_DATA],
      },
      [modules.MANAGE_MINIMUM_WAGE.key]: {
        module: modules.MANAGE_MINIMUM_WAGE.key,
        features: [features.READ_DATA],
      },
      [modules.MANAGE_PERIOD.key]: {
        module: modules.MANAGE_PERIOD.key,
        features: [features.READ_DATA],
      },
      [modules.MANAGE_PROFILE.key]: {
        module: modules.MANAGE_PROFILE.key,
        features: [features.READ_DATA],
      },
      [modules.MANAGE_USER.key]: {
        module: modules.MANAGE_USER.key,
        features: [features.READ_DATA],
      },
      [modules.MANAGE_USER_ROLE.key]: {
        module: modules.MANAGE_USER_ROLE.key,
        features: [features.READ_DATA],
      },
      [modules.MANAGE_WAGE.key]: {
        module: modules.MANAGE_WAGE.key,
        features: [features.READ_DATA],
      },
    },
    text: "Pengaturan",
    children: [
      {
        icon: <FontAwesomeIcon icon={faUserPen} />,
        moduleFeatures: {
          [modules.MANAGE_PROFILE.key]: {
            module: modules.MANAGE_PROFILE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_PROFILE.key.toLowerCase()}`,
        text: modules.MANAGE_PROFILE.value,
      },
      {
        icon: <FontAwesomeIcon icon={faBuildingLock} />,
        moduleFeatures: {
          [modules.MANAGE_COMPANY.key]: {
            module: modules.MANAGE_COMPANY.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_COMPANY.key.toLowerCase()}`,
        text: modules.MANAGE_COMPANY.value,
      },
      {
        icon: <FontAwesomeIcon icon={faUsersCog} />,
        moduleFeatures: {
          [modules.MANAGE_USER_ROLE.key]: {
            module: modules.MANAGE_USER_ROLE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_USER_ROLE.key.toLowerCase()}`,
        text: modules.MANAGE_USER_ROLE.value,
      },
      {
        icon: <FontAwesomeIcon icon={faUserGear} />,
        moduleFeatures: {
          [modules.MANAGE_USER.key]: {
            module: modules.MANAGE_USER.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_USER.key.toLowerCase()}`,
        text: modules.MANAGE_USER.value,
      },
      {
        icon: <FontAwesomeIcon icon={faHourglass} />,
        moduleFeatures: {
          [modules.MANAGE_PERIOD.key]: {
            module: modules.MANAGE_PERIOD.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_PERIOD.key.toLowerCase()}`,
        text: modules.MANAGE_PERIOD.value,
      },
      {
        icon: <FontAwesomeIcon icon={faSkull} />,
        moduleFeatures: {
          [modules.MANAGE_JKK_CONTRIBUTION.key]: {
            module: modules.MANAGE_JKK_CONTRIBUTION.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_JKK_CONTRIBUTION.key.toLowerCase()}`,
        text: modules.MANAGE_JKK_CONTRIBUTION.value,
      },
      {
        icon: <FontAwesomeIcon icon={faScaleUnbalanced} />,
        moduleFeatures: {
          [modules.MANAGE_MINIMUM_WAGE.key]: {
            module: modules.MANAGE_MINIMUM_WAGE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_MINIMUM_WAGE.key.toLowerCase()}`,
        text: modules.MANAGE_MINIMUM_WAGE.value,
      },
      {
        icon: <FontAwesomeIcon icon={faFilterCircleDollar} />,
        moduleFeatures: {
          [modules.MANAGE_WAGE.key]: {
            module: modules.MANAGE_WAGE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_WAGE.key.toLowerCase()}`,
        text: modules.MANAGE_WAGE.value,
      },
    ],
  },
];
