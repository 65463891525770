import { postMethodToken } from "../../utils/httpMethod/postMethodToken";

export const importWorkingDayTemplate = async (data) => {
  const res = await postMethodToken("admin/working_day/import_template", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
