const isDataGridCellEditable = (ruleString) => {
  let isEditable = true;

  if (!ruleString) {
    return isEditable;
  }

  const rules = ruleString.split("|");

  rules.forEach((rule) => {
    const temp = rule.split("_");
    const ruleKey = temp[0];

    if (ruleKey === "id" || ruleKey === "formula") {
      isEditable = false;
    }
  });

  return isEditable;
};

export default isDataGridCellEditable;
