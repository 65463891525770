const getSingleSelectFromRules = (ruleString) => {
  let result = null;

  if (!ruleString) {
    return result;
  }

  const rules = ruleString.split("|");

  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    const temp = rule.split("_");
    const ruleKey = temp[0];
    const ruleValue = temp[1];

    if (ruleKey === "dropdown") {
      result = ruleValue;
    }
  }

  return result;
};

module.exports = getSingleSelectFromRules;
