import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Paper, Tab } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import ManageUserRoleCreate from "./ManageUserRoleCreate";
import ManageUserRoleList from "./ManageUserRoleList";

const ManageUserRole = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label="Tambah Peran" value="0" />
            <Tab label="Daftar Peran" value="1" />
          </TabList>
        </Box>
        <AuthElement
          isShowForbidden
          moduleFeatures={{
            [modules.MANAGE_USER_ROLE.key]: {
              module: modules.MANAGE_USER_ROLE.key,
              features: [features.READ_DATA],
            },
          }}
        >
          <TabPanel value="0">
            <ManageUserRoleCreate />
          </TabPanel>
        </AuthElement>
        <AuthElement
          isShowForbidden
          moduleFeatures={{
            [modules.MANAGE_USER_ROLE.key]: {
              module: modules.MANAGE_USER_ROLE.key,
              features: [features.READ_DATA],
            },
          }}
        >
          <TabPanel value="1">
            <ManageUserRoleList />
          </TabPanel>
        </AuthElement>
      </TabContext>
    </Paper>
  );
};

export default ManageUserRole;
