export const taxSummaryColumn = [
  { field: "tax_summary_id", headerName: "ID", minWidth: 70 },
  {
    field: "nip",
    headerName: "NIP",
    minWidth: 250,
  },
  {
    field: "gaji_bruto_januari",
    headerName: "Gaji Bruto Januari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_februari",
    headerName: "Gaji Bruto Februari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_maret",
    headerName: "Gaji Bruto Maret",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_april",
    headerName: "Gaji Bruto April",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_mei",
    headerName: "Gaji Bruto Mei",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_juni",
    headerName: "Gaji Bruto Juni",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_juli",
    headerName: "Gaji Bruto Juli",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_agustus",
    headerName: "Gaji Bruto Agustus",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_september",
    headerName: "Gaji Bruto September",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_oktober",
    headerName: "Gaji Bruto Oktober",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_november",
    headerName: "Gaji Bruto November",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_bruto_desember",
    headerName: "Gaji Bruto Desember",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_januari",
    headerName: "PPH21 Januari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_februari",
    headerName: "PPH21 Februari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_maret",
    headerName: "PPH21 Maret",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_april",
    headerName: "PPH21 April",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_mei",
    headerName: "PPH21 Mei",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_juni",
    headerName: "PPH21 Juni",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_juli",
    headerName: "PPH21 Juli",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_agustus",
    headerName: "PPH21 Agustus",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_september",
    headerName: "PPH21 September",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_oktober",
    headerName: "PPH21 Oktober",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_november",
    headerName: "PPH21 November",
    minWidth: 250,
    type: "number",
  },
  {
    field: "pph21_desember",
    headerName: "PPH21 Desember",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_januari",
    headerName: "JHT Januari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_februari",
    headerName: "JHT Februari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_maret",
    headerName: "JHT Maret",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_april",
    headerName: "JHT April",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_mei",
    headerName: "JHT Mei",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_juni",
    headerName: "JHT Juni",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_juli",
    headerName: "JHT Juli",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_agustus",
    headerName: "JHT Agustus",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_september",
    headerName: "JHT September",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_oktober",
    headerName: "JHT Oktober",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_november",
    headerName: "JHT November",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_desember",
    headerName: "JHT Desember",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_januari",
    headerName: "JP Januari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_februari",
    headerName: "JP Februari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_maret",
    headerName: "JP Maret",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_april",
    headerName: "JP April",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_mei",
    headerName: "JP Mei",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_juni",
    headerName: "JP Juni",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_juli",
    headerName: "JP Juli",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_agustus",
    headerName: "JP Agustus",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_september",
    headerName: "JP September",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_oktober",
    headerName: "JP Oktober",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_november",
    headerName: "JP November",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_desember",
    headerName: "JP Desember",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_januari",
    headerName: "Insentif Januari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_februari",
    headerName: "Insentif Februari",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_maret",
    headerName: "Insentif Maret",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_april",
    headerName: "Insentif April",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_mei",
    headerName: "Insentif Mei",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_juni",
    headerName: "Insentif Juni",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_juli",
    headerName: "Insentif Juli",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_agustus",
    headerName: "Insentif Agustus",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_september",
    headerName: "Insentif September",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_oktober",
    headerName: "Insentif Oktober",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_november",
    headerName: "Insentif November",
    minWidth: 250,
    type: "number",
  },
  {
    field: "insentif_desember",
    headerName: "Insentif Desember",
    minWidth: 250,
    type: "number",
  },
];
