import {
  faFileArrowDown,
  faFileArrowUp,
  faFileExport,
  faFileImport,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { parseISO } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AuthElement from "../../../../components/AuthElement/AuthElement";
import AdminDataGridPro from "../../../../components/admin/AdminDataGridPro";
import { features } from "../../../../constants/features";
import { modules } from "../../../../constants/modules";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { createManualAttendanceWeek2 } from "../../../../services/manualAttendanceWeek2/createManualAttendanceWeek2";
import { deleteManualAttendanceWeek2 } from "../../../../services/manualAttendanceWeek2/deleteManualAttendanceWeek2";
import { exportManualAttendanceWeek2Data } from "../../../../services/manualAttendanceWeek2/exportManualAttendanceWeek2Data";
import { exportManualAttendanceWeek2Template } from "../../../../services/manualAttendanceWeek2/exportManualAttendanceWeek2Template";
import { getManualAttendanceWeek2 } from "../../../../services/manualAttendanceWeek2/getManualAttendanceWeek2";
import { getManualAttendanceWeek2Filename } from "../../../../services/manualAttendanceWeek2/getManualAttendanceWeek2Filename";
import { getManualAttendanceWeek2TemplateFilename } from "../../../../services/manualAttendanceWeek2/getManualAttendanceWeek2TemplateFilename";
import { importManualAttendanceWeek2Data } from "../../../../services/manualAttendanceWeek2/importManualAttendanceWeek2Data";
import { importManualAttendanceWeek2Template } from "../../../../services/manualAttendanceWeek2/importManualAttendanceWeek2Template";
import { replaceManualAttendanceWeek2Data } from "../../../../services/manualAttendanceWeek2/replaceManualAttendanceWeek2Data";
import { updateManualAttendanceWeek2 } from "../../../../services/manualAttendanceWeek2/updateManualAttendanceWeek2";
import { getSingleSelectData } from "../../../../services/singleSelect/getSingleSelectData";
import convertRulesToMUIDataType from "../../../../utils/convertRulesToMUIDataType";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";
import getDataGridColumnWidth from "../../../../utils/getDataGridColumnWidth";
import getSingleSelectFromRules from "../../../../utils/getSingleSelectFromRules";
import isDataGridCellEditable from "../../../../utils/isDataGridCellEditable";
import isValidDate from "../../../../utils/isValidDate";

const ManualAttendanceWeek2 = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataGridState, setDataGridState] = useState({
    columns: [],
    columnVisibilityModel: {},
    filterModel: {},
    isLoading: false,
    pinnedRows: { top: [], bottom: [] },
    rowId: "manual_attendance_week_2_id",
    rowSelectionModel: [],
    rows: [],
    sortModel: [],
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
    buttonDelete: false,
    buttonExportData: false,
    buttonExportTemplate: false,
    buttonImportData: false,
    buttonImportTemplate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const importManualAttendanceWeek2DataRef = useRef();
  const importManualAttendanceWeek2TemplateRef = useRef();
  const open = Boolean(anchorEl);
  const replaceManualAttendanceWeek2DataRef = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const fetchManualAttendanceWeek2Data = async () => {
    try {
      // get drop down value
      let singleSelectData = await fetchSingleSelectData();

      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getManualAttendanceWeek2();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        initManualAttendanceWeek2DataGrid(
          res.payload.query,
          singleSelectData,
          res.templateData
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchSingleSelectData = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getSingleSelectData(
        modules.MANUAL_ATTENDANCE_WEEK_2.key.toLowerCase()
      );

      if (res.status === 200) {
        return res.payload;
      } else {
        return [];
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCreateManualAttendanceWeek2 = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      let res = await getManualAttendanceWeek2TemplateFilename();

      if (res.status !== 200) {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await createManualAttendanceWeek2({
        newManualAttendanceWeek2Data: dataGridState.rows[0],
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      // if success then fetch
      if (res.status === 200) {
        fetchManualAttendanceWeek2Data();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleDeleteManualAttendanceWeek2 = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonDelete: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await deleteManualAttendanceWeek2({
        selectedManualAttendanceWeek2ID: dataGridState.rowSelectionModel,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      // if success then fetch
      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rowSelectionModel: [],
        }));

        fetchManualAttendanceWeek2Data();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonDelete: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportManualAttendanceWeek2Data = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let res = await getManualAttendanceWeek2TemplateFilename();

      if (res.status !== 200) {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      let filename = null;

      res = await getManualAttendanceWeek2Filename();

      if (res.status === 200) {
        filename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportManualAttendanceWeek2Data(
        {
          columnVisibilityModel: dataGridState.columnVisibilityModel,
          filterModel: dataGridState.filterModel,
          sortModel: dataGridState.sortModel,
        },
        { responseType: "arraybuffer" }
      );

      const link = document.createElement("a");
      const tempFilename = filename;
      link.setAttribute("download", tempFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Data Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportManualAttendanceWeek2Template = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportTemplate: true,
      }));
      setIsLoadingLinearProgress(true);

      let templateFilename = null;

      let res = await getManualAttendanceWeek2TemplateFilename();

      if (res.status === 200) {
        templateFilename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportManualAttendanceWeek2Template({
        responseType: "arraybuffer",
      });

      const link = document.createElement("a");
      const tempTemplateFilename = templateFilename;
      link.setAttribute("download", tempTemplateFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Template Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportTemplate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleImportManualAttendanceWeek2Data = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "manualAttendanceWeek2Data",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await importManualAttendanceWeek2Data(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchManualAttendanceWeek2Data();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("manual-attendance-week-2-data")) {
        document.getElementById("manual-attendance-week-2-data").value = "";
      }
    }
  };

  const handleImportManualAttendanceWeek2Template = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportTemplate: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "manualAttendanceWeek2Template",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await importManualAttendanceWeek2Template(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchManualAttendanceWeek2Data();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportTemplate: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("manual-attendance-week-2-template")) {
        document.getElementById("manual-attendance-week-2-template").value = "";
      }
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReplaceManualAttendanceWeek2Data = async (e) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();
      const filename = uuidv4();

      formData.append(
        "manualAttendanceWeek2Data",
        e.target.files[0],
        `${filename}.${e.target.files[0].name.split(".").pop()}`
      );

      const res = await replaceManualAttendanceWeek2Data(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchManualAttendanceWeek2Data();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonImportData: false,
      }));
      setIsLoadingLinearProgress(false);

      if (document.getElementById("manual-attendance-week-2-data")) {
        document.getElementById("manual-attendance-week-2-data").value = "";
      }
    }
  };

  const handleUpdateManualAttendanceWeek2 = async (newRow) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      // if update insert row
      if (newRow.manual_attendance_week_2_id === 0) {
        const tempNewManualAttendanceWeek2Data = dataGridState.rows.map(
          (currentState) => {
            return currentState.manual_attendance_week_2_id ===
              newRow.manual_attendance_week_2_id
              ? newRow
              : currentState;
          }
        );

        setDataGridState((prevState) => ({
          ...prevState,
          rows: tempNewManualAttendanceWeek2Data,
        }));
      } else {
        // update data at DB
        try {
          setDataGridState((prevState) => ({
            ...prevState,
            isLoading: true,
          }));
          setIsLoadingLinearProgress(true);

          const res = await updateManualAttendanceWeek2({
            newManualAttendanceWeek2Data: newRow,
          });

          snackbarContext.handleOpenSnackbar(res.message, res.status);

          fetchManualAttendanceWeek2Data();
        } catch (error) {
          snackbarContext.handleOpenSnackbar(
            generateSnackbarErrorMessage(error),
            "error"
          );
          throw error;
        } finally {
          setDataGridState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          setIsLoadingLinearProgress(false);
        }
      }
    } catch (error) {
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const initManualAttendanceWeek2DataGrid = (
    data,
    singleSelectData,
    templateData
  ) => {
    const gridColumns = [];
    const gridRows = [];
    const insertRow = {};

    // init column & insert row
    for (let i = 0; i < templateData.columns.length; i++) {
      let temp = {
        description:
          convertRulesToMUIDataType(templateData.rules[i]) === "date"
            ? "mm/dd/yyyy"
            : null,
        // if not id or formula then can edit
        editable: isDataGridCellEditable(templateData.rules[i]),
        field: templateData.fields[i],
        headerName: templateData.columns[i],
        minWidth: getDataGridColumnWidth(templateData.rules[i]),
        type: convertRulesToMUIDataType(templateData.rules[i]),
      };

      if (convertRulesToMUIDataType(templateData.rules[i]) === "singleSelect") {
        temp.valueOptions =
          singleSelectData[getSingleSelectFromRules(templateData.rules[i])];
      }

      gridColumns.push(temp);

      if (templateData.fields[i] === "manual_attendance_week_2_id") {
        insertRow[templateData.fields[i]] = 0;
      } else if (
        // avoid error (mui single select value cannot null)
        convertRulesToMUIDataType(templateData.rules[i]) === "singleSelect"
      ) {
        insertRow[templateData.fields[i]] = "";
      } else {
        insertRow[templateData.fields[i]] = null;
      }
    }

    gridRows.push(insertRow);

    // init row
    for (let i = 0; i < data.length; i++) {
      const keys = Object.keys(data[i]);

      for (let j = 0; j < keys.length; j++) {
        if (convertRulesToMUIDataType(templateData.rules[j]) === "date") {
          data[i][keys[j]] = parseISO(data[i][keys[j]]);

          if (!isValidDate(data[i][keys[j]])) {
            data[i][keys[j]] = "";
          }
        }
      }

      gridRows.push(data[i]);
    }

    setDataGridState((prevState) => ({
      ...prevState,
      columns: gridColumns,
      rows: gridRows,
    }));
  };

  useEffect(() => {
    fetchManualAttendanceWeek2Data();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={"flex-end"}
        spacing={2}
        sx={{ mb: 2 }}
      >
        <AuthElement
          moduleFeatures={{
            [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
              module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
              features: [features.CREATE_DATA],
            },
          }}
        >
          <LoadingButton
            endIcon={<AddIcon />}
            loading={isLoadingButtonState.buttonCreate}
            loadingPosition="end"
            onClick={handleCreateManualAttendanceWeek2}
          >
            Create
          </LoadingButton>
        </AuthElement>
        <AuthElement
          moduleFeatures={{
            [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
              module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
              features: [features.DELETE_DATA],
            },
          }}
        >
          <LoadingButton
            disabled={dataGridState.rowSelectionModel.length <= 0}
            endIcon={<DeleteIcon />}
            loading={isLoadingButtonState.buttonDelete}
            loadingPosition="end"
            onClick={() => {
              dialogContext.handleOpenDialog(
                () => handleDeleteManualAttendanceWeek2(),
                "Are you sure want to delete?",
                "Delete Confirmation"
              );
            }}
          >
            Delete
          </LoadingButton>
        </AuthElement>
        <AuthElement
          moduleFeatures={{
            [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
              module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
              features: [
                features.EXPORT_DATA,
                features.EXPORT_TEMPLATE,
                features.IMPORT_DATA,
                features.IMPORT_TEMPLATE,
              ],
            },
          }}
        >
          <LoadingButton
            endIcon={<ExpandMoreIcon />}
            loading={
              isLoadingButtonState.buttonExportData ||
              isLoadingButtonState.buttonExportTemplate ||
              isLoadingButtonState.buttonImportData ||
              isLoadingButtonState.buttonImportTemplate
            }
            loadingPosition="end"
            onClick={handleOpenMenu}
          >
            More
          </LoadingButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            onClose={handleCloseMenu}
            open={open}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <AuthElement
              moduleFeatures={{
                [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
                  module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
                  features: [features.IMPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  replaceManualAttendanceWeek2DataRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faUpload} />
                </ListItemIcon>
                <ListItemText>
                  Replace Data
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="manual-attendance-week-2-data"
                    onChange={(e) => {
                      dialogContext.handleOpenDialog(
                        () => handleReplaceManualAttendanceWeek2Data(e),
                        "Your data will be lost, are you sure to continue?",
                        "Replace Data Confirmation"
                      );
                      handleCloseMenu();
                    }}
                    ref={replaceManualAttendanceWeek2DataRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
                  module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
                  features: [features.IMPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  importManualAttendanceWeek2DataRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileImport} />
                </ListItemIcon>
                <ListItemText>
                  Import Data
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="manual-attendance-week-2-data"
                    onChange={(e) => {
                      handleImportManualAttendanceWeek2Data(e);
                      handleCloseMenu();
                    }}
                    ref={importManualAttendanceWeek2DataRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
                  module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
                  features: [features.EXPORT_DATA],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleExportManualAttendanceWeek2Data();
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileExport} />
                </ListItemIcon>
                <ListItemText>Export Data</ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
                  module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
                  features: [features.IMPORT_TEMPLATE],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  importManualAttendanceWeek2TemplateRef.current.click();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileArrowUp} />
                </ListItemIcon>
                <ListItemText>
                  Import Template
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    hidden
                    id="manual-attendance-week-2-template"
                    onChange={(e) => {
                      dialogContext.handleOpenDialog(
                        () => handleImportManualAttendanceWeek2Template(e),
                        "Your data will be lost, are you sure to continue?",
                        "Import Template Confirmation"
                      );
                      handleCloseMenu();
                    }}
                    ref={importManualAttendanceWeek2TemplateRef}
                    type="file"
                  />
                </ListItemText>
              </MenuItem>
            </AuthElement>
            <AuthElement
              moduleFeatures={{
                [modules.MANUAL_ATTENDANCE_WEEK_2.key]: {
                  module: modules.MANUAL_ATTENDANCE_WEEK_2.key,
                  features: [features.EXPORT_TEMPLATE],
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleExportManualAttendanceWeek2Template();
                  handleCloseMenu();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileArrowDown} />
                </ListItemIcon>
                <ListItemText>Export Template</ListItemText>
              </MenuItem>
            </AuthElement>
          </Menu>
        </AuthElement>
      </Stack>
      <AdminDataGridPro
        columns={dataGridState.columns}
        dataGridState={dataGridState}
        processRowUpdate={handleUpdateManualAttendanceWeek2}
        setDataGridState={setDataGridState}
      />
    </>
  );
};

export default ManualAttendanceWeek2;
