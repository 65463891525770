import { postMethodToken } from "../../utils/httpMethod/postMethodToken";

export const importGradeTemplate = async (data) => {
  const res = await postMethodToken("admin/grade/import_template", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
