import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import Holiday from "./Holiday";
import Period from "./Period";
import WorkSchedule from "./WorkSchedule";

const CompanyAttendance = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label={modules.HOLIDAY.value} value="0" />
            <Tab label={modules.PERIOD.value} value="1" />
            <Tab label={modules.WORK_SCHEDULE.value} value="2" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.HOLIDAY.key]: {
                module: modules.HOLIDAY.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <Holiday />
          </AuthElement>
        </TabPanel>
        <TabPanel value="1">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.PERIOD.key]: {
                module: modules.PERIOD.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <Period />
          </AuthElement>
        </TabPanel>
        <TabPanel value="2">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.WORK_SCHEDULE.key]: {
                module: modules.WORK_SCHEDULE.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <WorkSchedule />
          </AuthElement>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default CompanyAttendance;
