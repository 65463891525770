const getDataGridColumnWidth = (ruleString) => {
  let columnWidth = 250;

  if (!ruleString) {
    return columnWidth;
  }

  const rules = ruleString.split("|");

  rules.forEach((rule) => {
    const temp = rule.split("_");
    const ruleKey = temp[0];

    if (ruleKey === "id") {
      columnWidth = 70;
    } else if (ruleKey === "date") {
      columnWidth = 250;
    }
  });

  return columnWidth;
};

export default getDataGridColumnWidth;
