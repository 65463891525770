const convertRulesToMUIDataType = (ruleString) => {
  let dataType = "";

  if (!ruleString) {
    return "string";
  }

  const rules = ruleString.split("|");

  rules.forEach((rule) => {
    const temp = rule.split("_");
    const ruleKey = temp[0];

    if (ruleKey === "date") {
      dataType = "date";
    } else if (ruleKey === "dropdown") {
      dataType = "singleSelect";
    } else if (
      ruleKey === "id" ||
      ruleKey === "number" ||
      ruleKey === "positive"
    ) {
      dataType = "number";
    }
  });

  if (!dataType) {
    dataType = "string";
  }

  return dataType;
};

export default convertRulesToMUIDataType;
