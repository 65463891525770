export const features = {
  CREATE_DATA: "Create Data",
  DELETE_DATA: "Delete Data",
  EXPORT_DATA: "Export Data",
  EXPORT_REPORT: "Export Report",
  EXPORT_TEMPLATE: "Export Template",
  IMPORT_DATA: "Import Data",
  IMPORT_TEMPLATE: "Import Template",
  READ_DATA: "Read Data",
  UPDATE_DATA: "Update Data",
};
