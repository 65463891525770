import { darkTheme } from "../themes/darkTheme";
import { lightTheme } from "../themes/lightTheme";

export const getTheme = () => {
  if (localStorage.getItem("theme")) {
    if (localStorage.getItem("theme") === "dark") {
      return darkTheme;
    } else {
      return lightTheme;
    }
  } else {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return darkTheme;
    } else {
      return lightTheme;
    }
  }
};
