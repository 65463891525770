export const bpjsCalculationColumn = [
  { field: "bpjs_calculation_id", headerName: "ID", minWidth: 70 },
  {
    field: "nip",
    headerName: "NIP",
    minWidth: 250,
  },
  {
    field: "nama",
    headerName: "Nama",
    minWidth: 250,
  },
  {
    field: "umk",
    headerName: "UMK",
    minWidth: 250,
    type: "number",
  },
  {
    field: "gaji_pokok",
    headerName: "Gaji Pokok",
    minWidth: 250,
    type: "number",
  },
  {
    field: "tunjangan_tetap",
    headerName: "Tunjangan Tetap",
    minWidth: 250,
    type: "number",
  },
  {
    field: "upah_kesepakatan",
    headerName: "Upah Kesepakatan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "upah_penyesuaian_bpjs_tk",
    headerName: "Upah Penyesuaian BPJS TK",
    minWidth: 250,
    type: "number",
  },
  {
    field: "dasar_upah_bpjs_tk",
    headerName: "Dasar Upah BPJS TK",
    minWidth: 250,
    type: "number",
  },
  {
    field: "dasar_upah_bpjs_jp",
    headerName: "Dasar Upah BPJS JP",
    minWidth: 250,
    type: "number",
  },
  {
    field: "status_bpjs_tk",
    headerName: "Status BPJS TK",
    minWidth: 250,
  },
  {
    field: "jkk_perusahaan",
    headerName: "JKK Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jkm_perusahaan",
    headerName: "JKM Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_perusahaan",
    headerName: "JHT Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jht_pegawai",
    headerName: "JHT Pegawai",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_perusahaan",
    headerName: "JP perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "jp_pegawai",
    headerName: "JP pegawai",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_iuran_bpjs_tk",
    headerName: "Total Iuran BPJS TK",
    minWidth: 250,
    type: "number",
  },
  {
    field: "upah_penyesuaian_bpjs_kesehatan",
    headerName: "Upah Penyesuaian BPJS Kesehatan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "dasar_upah_bpjs_kesehatan",
    headerName: "Dasar Upah BPJS Kesehatan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "status_bpjs_kesehatan",
    headerName: "Status BPJS Kesehatan",
    minWidth: 250,
  },
  {
    field: "bpjs_kesehatan_perusahaan",
    headerName: "BPJS Kesehatan Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_kesehatan_pegawai",
    headerName: "BPJS Kesehatan Pegawai",
    minWidth: 250,
    type: "number",
  },
  {
    field: "tambahan_keluarga",
    headerName: "Tambahan Keluarga",
    minWidth: 250,
    type: "number",
  },
  {
    field: "bpjs_kesehatan_tambahan_keluarga",
    headerName: "BPJS Kesehatan Tambahan Keluarga",
    minWidth: 250,
    type: "number",
  },
  {
    field: "total_iuran_bpjs_kesehatan",
    headerName: "Total Iuran BPJS Kesehatan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "potongan_bpjs_perusahaan",
    headerName: "Potongan BPJS Perusahaan",
    minWidth: 250,
    type: "number",
  },
  {
    field: "potongan_bpjs_pegawai",
    headerName: "Potongan BPJS Pegawai",
    minWidth: 250,
    type: "number",
  },
];
