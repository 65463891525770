import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, useTheme } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { adminDrawerItems } from "../../../constants/adminDrawerItems";
import { generateBreadcrumbs } from "../../../utils/generateBreadcrumbs";
import styles from "./MyBreadcrumbs.module.scss";

const detailIcon = <FontAwesomeIcon icon={faCircleInfo} />;

const CustomPropsBreadcrumb = ({ icon, text }) => (
  <>
    {
      <span
        className={`${styles["breadcrumbs__icon"]} 
        ${icon && styles["breadcrumbs__icon--margin-right"]}`}
      >
        {icon}
      </span>
    }
    <span className={styles["breadcrumbs__text"]}>{text}</span>
  </>
);

export const additionalBreadcrumbs = [];

const routes = additionalBreadcrumbs.concat(
  generateBreadcrumbs(adminDrawerItems, detailIcon, CustomPropsBreadcrumb)
);

const MyBreadcrumbs = () => {
  const breadcrumbs = useReactRouterBreadcrumbs(routes, {
    excludePaths: ["/", "/admin"],
  });
  const theme = useTheme();

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      sx={{ mb: 3 }}
    >
      {breadcrumbs.map(({ breadcrumb, disabled, match }) => (
        <NavLink
          className={`${styles["breadcrumbs"]}
            ${disabled && styles["breadcrumbs--disabled"]}`}
          key={match.pathname}
          style={{ color: theme.palette.text.secondary }}
          to={match.pathname}
        >
          {breadcrumb}
        </NavLink>
      ))}
    </Breadcrumbs>
  );
};

export default MyBreadcrumbs;
