import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import { Paper, Stack } from "@mui/material";
import { parseISO } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import AuthElement from "../../../components/AuthElement/AuthElement";
import AdminDataGridPro from "../../../components/admin/AdminDataGridPro";
import { salaryCalculationColumn } from "../../../constants/columns/salaryCalculationColumn";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { exportSalaryCalculationData } from "../../../services/salaryCalculation/exportSalaryCalculationData";
import { getSalaryCalculation } from "../../../services/salaryCalculation/getSalaryCalculation";
import { getSalaryCalculationFilename } from "../../../services/salaryCalculation/getSalaryCalculationFilename";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";
import isInteger from "../../../utils/isInteger";
import isValidDate from "../../../utils/isValidDate";

const SalaryCalculation = () => {
  const [dataGridState, setDataGridState] = useState({
    columns: salaryCalculationColumn,
    columnVisibilityModel: {},
    filterModel: {},
    isLoading: false,
    pinnedRows: { top: [], bottom: [] },
    rowId: "salary_calculation_id",
    rowSelectionModel: [],
    rows: [],
    sortModel: [],
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonExportData: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const snackbarContext = useContext(SnackbarContext);

  const fetchSalaryCalculationData = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getSalaryCalculation();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        initSalaryCalculationDataGrid(res.payload.query);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportSalaryCalculationData = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: true,
      }));
      setIsLoadingLinearProgress(true);

      let filename = null;
      let res = await getSalaryCalculationFilename();

      if (res.status === 200) {
        filename = res.payload;
      } else {
        snackbarContext.handleOpenSnackbar(res.message, res.status);

        return;
      }

      res = await exportSalaryCalculationData(
        {
          columnVisibilityModel: dataGridState.columnVisibilityModel,
          filterModel: dataGridState.filterModel,
          sortModel: dataGridState.sortModel,
        },
        { responseType: "arraybuffer" }
      );

      const link = document.createElement("a");
      const tempFilename = filename;
      link.setAttribute("download", tempFilename);
      link.href = URL.createObjectURL(new Blob([res]));
      document.body.appendChild(link);
      link.click();
      link.remove();

      snackbarContext.handleOpenSnackbar("Data Exported", 200);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonExportData: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const initSalaryCalculationDataGrid = (data) => {
    let tempData = data;

    for (let i = 0; i < tempData.length; i++) {
      let tempKeys = Object.keys(tempData[i]);

      for (let j = 0; j < tempKeys.length; j++) {
        if (!isInteger(tempData[i][tempKeys[j]])) {
          let tempDate = parseISO(tempData[i][tempKeys[j]]);

          if (isValidDate(tempDate)) {
            tempData[i][tempKeys[j]] = tempDate;
          }
        }
      }
    }

    let tempQuery = {};

    if (data.length > 0) {
      tempQuery = structuredClone(data[0]);
      tempQuery.salary_calculation_id = 0;

      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        const keys = Object.keys(element);
        const values = Object.values(element);

        for (let j = 0; j < keys.length; j++) {
          const tempKeys = keys[j];
          const tempValues = values[j];

          if (isInteger(tempValues)) {
            tempQuery[tempKeys] =
              parseInt(tempQuery[tempKeys]) + parseInt(tempValues);
          } else {
            tempQuery[tempKeys] = 0;
          }
        }
      }

      data.push(tempQuery);
    }

    setDataGridState((prevState) => ({
      ...prevState,
      pinnedRows: {
        top: [],
        bottom: data.length > 0 ? [tempQuery] : [],
      },
      rows: tempData,
    }));
  };

  useEffect(() => {
    fetchSalaryCalculationData();

    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={"flex-end"}
        spacing={2}
        sx={{ mb: 2 }}
      >
        <AuthElement
          moduleFeatures={{
            [modules.BPJS_CALCULATION.key]: {
              module: modules.BPJS_CALCULATION.key,
              features: [features.EXPORT_DATA],
            },
          }}
        >
          <LoadingButton
            endIcon={<FileDownloadIcon />}
            loading={isLoadingButtonState.buttonExportData}
            loadingPosition="end"
            onClick={() => {
              handleExportSalaryCalculationData();
            }}
          >
            Export Data
          </LoadingButton>
        </AuthElement>
      </Stack>
      <AdminDataGridPro
        columns={dataGridState.columns}
        dataGridState={dataGridState}
        processRowUpdate={() => {}}
        setDataGridState={setDataGridState}
      />
    </Paper>
  );
};

export default SalaryCalculation;
