import { features } from "../constants/features";
import { landingPageOrder } from "../constants/landingPageOrder";

export const getLandingPage = () => {
  let landingPage = "dashboard";
  let modules = [];
  const userRoleJson = localStorage.getItem("user_role_json")
    ? JSON.parse(localStorage.getItem("user_role_json"))
    : [];

  for (let i = 0; i < userRoleJson.length; i++) {
    const userRoleJsonValue = userRoleJson[i];

    if (
      userRoleJsonValue.features.find((feature) => {
        return feature === features.READ_DATA || features.CREATE_DATA;
      })
    ) {
      modules.push(userRoleJsonValue.module);
    }
  }

  const tempLandingPageOrder = Object.values(landingPageOrder);
  let found = false;

  for (let i = 0; i < tempLandingPageOrder.length; i++) {
    if (!found && modules.includes(tempLandingPageOrder[i].key)) {
      found = true;
      landingPage = tempLandingPageOrder[i].url;
    }
  }

  return landingPage;
};
