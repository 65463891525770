import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { labels } from "../../../constants/labels";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { getJkkContribution } from "../../../services/manageJkkContribution/getJkkContribution";
import { getJkkContributionAutocomplete } from "../../../services/manageJkkContribution/getJkkContributionAutocomplete";
import { updateJkkContribution } from "../../../services/manageJkkContribution/updateJkkContribution";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";

const ManageJkkContribution = () => {
  const [
    autocompleteManageJkkContribution,
    setAutocompleteManageJkkContribution,
  ] = useState([]);
  const [
    isOpenAutocompleteManageJkkContribution,
    setIsOpenAutocompleteManageJkkContribution,
  ] = useState(false);
  const [
    isLoadingAutocompleteManageJkkContribution,
    setIsLoadingAutocompleteManageJkkContribution,
  ] = useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [selectedManageJkkContribution, setSelectedManageJkkContribution] =
    useState(null);
  const snackbarContext = useContext(SnackbarContext);

  const fetchJkkContribution = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getJkkContribution();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setSelectedManageJkkContribution(res.payload);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateJkkContribution = async () => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await updateJkkContribution({
        manage_jkk_contribution_jkk_contribution_id:
          selectedManageJkkContribution?.jkk_contribution_id ?? 0,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
      throw error;
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // fetch minimum wage autocomplete
  useEffect(() => {
    setAutocompleteManageJkkContribution([]);

    if (!isOpenAutocompleteManageJkkContribution) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteManageJkkContribution(true);

        const res = await getJkkContributionAutocomplete();

        if (res.status === 200) {
          setAutocompleteManageJkkContribution(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
        throw error;
      } finally {
        setIsLoadingAutocompleteManageJkkContribution(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteManageJkkContribution]);

  useEffect(() => {
    fetchJkkContribution();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Autocomplete
            disabled={isLoadingLinearProgress}
            getOptionLabel={(option) =>
              option[labels.JKK_CONTRIBUTION.KELOMPOK]
            }
            isOptionEqualToValue={(option, value) =>
              option.jkk_contribution_id === value.jkk_contribution_id
            }
            loading={isLoadingAutocompleteManageJkkContribution}
            onChange={(_, value) => {
              setSelectedManageJkkContribution(value);
            }}
            open={isOpenAutocompleteManageJkkContribution}
            onOpen={() => {
              setIsOpenAutocompleteManageJkkContribution(true);
            }}
            onClose={() => {
              setIsOpenAutocompleteManageJkkContribution(false);
            }}
            options={autocompleteManageJkkContribution}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Kelompok Iuran"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoadingAutocompleteManageJkkContribution ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                placeholder="Pilih Kelompok Iuran"
              />
            )}
            value={selectedManageJkkContribution}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <TextField
            disabled
            fullWidth
            label="Persentase"
            value={`${
              selectedManageJkkContribution?.[
                labels.JKK_CONTRIBUTION.PERSENTASE
              ] ?? 0
            } %
              `}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <TextField
            disabled
            fullWidth
            label="Keterangan"
            value={
              selectedManageJkkContribution?.[
                labels.JKK_CONTRIBUTION.KETERANGAN
              ] ?? ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<SaveIcon />}
              loading={isLoadingButtonState.buttonUpdate}
              loadingPosition="end"
              onClick={handleUpdateJkkContribution}
              sx={{ mt: 2 }}
            >
              Save
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManageJkkContribution;
